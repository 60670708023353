import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useHistory,
} from "react-router-dom";
import Login from "./shared/Login/login";
import Dashboard from "./shared/Dashboard/dashboard";
import Students from "./shared/Students/Students";
import Categories from "./shared/categories/categories";
import Subcategories from "./shared/Subcategories/subcategories";
import Tags from "./shared/Tags/tags";
import Business from "./shared/Business/Business";
import "./style.css";
import Events from "./shared/Events/Events";
import Grouptags from "./shared/Grouptags/Grouptags";
import AddEvents from "./shared/Events/AddEvents";
import UpdateEvents from "./shared/Events/UpdateEvents";
import Articles from "./shared/Articles/Articles";
import AddArticles from "./shared/Articles/AddArticles";
import UpdateArticles from "./shared/Articles/UpdateArticles";
import Faqs from "./shared/Faqs/Faqs";
import AddFaqs from "./shared/Faqs/AddFaqs";
import UpdateFaqs from "./shared/Faqs/UpdateFaqs";
import Sections from "./shared/Sections/Sections";
import UpdateSections from "./shared/Sections/updateSections";
import Product_Category_Group from "./shared/Procuct_Category_Group/productCategoryGroup";
import Product_Categories from "./shared/Product_Categories/productCategories";
import GroupsData from "./shared/Groups/GroupsData";
import Info from "./shared/InformationText/Info";
import Levelgroup from "./shared/LevelGroup/Levelgroup";
import Product from "./shared/Product/Product";
import Branch from "./shared/Branch/Branch";
import Image from "./shared/Images/Image";
import UserRoleManagement from "./shared/UserRoleManagement/UserRoleManagement";
import DuplicateBranch from "./shared/Branch/duplicateBranches";
import DuplicateBusiness from "./shared/Business/DuplicateBusiness";
import Programmes from "./shared/Programms/Programms";
import OtpVerification from "./shared/OtpVerification/OtpVerification";
import IdleTimer from "./utils/idleTimer";

const PrivateRoute = (props) => {
  const history = useHistory();
  const token = sessionStorage.getItem("token");
  const _expiredTime = sessionStorage.getItem("_expiredTime");

  const [isTimeout, setIsTimeout] = useState(false);

  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 7200, //expire after 2 hours
      onTimeout: () => {
        setIsTimeout(true);
      },
      onExpired: () => {
        //do something if expired on load
        setIsTimeout(true);
      },
    });

    return () => {
      if (!_expiredTime) {
        timer.cleanUp();
      }
    };
  }, []);

  if (isTimeout) {
    history.replace("/login");
  }

  if (token) {
    return <Route {...props} />;
  }
  return (
    <Redirect
      to={{
        pathname: "/login",
      }}
    />
  );
};

const PublicRoute = (props) => {
  const token = sessionStorage.getItem("token");

  return token ? (
    <Redirect to={{ pathname: "/dashboard" }} />
  ) : (
    <Route {...props} />
  );
};

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <PublicRoute exact path="/" component={Login}></PublicRoute>
          <PublicRoute exact path="/login" component={Login} />
          <PublicRoute exact path="/verification" component={OtpVerification} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/Students" component={Students} />
          <PrivateRoute exact path="/Business" component={Business} />
          <PrivateRoute
            exact
            path="/DuplicateBusiness"
            component={DuplicateBusiness}
          />
          <PrivateRoute exact path="/Branch" component={Branch} />
          <PrivateRoute
            exact
            path="/DuplicateBranch"
            component={DuplicateBranch}
          />
          <PrivateRoute exact path="/Events" component={Events} />
          <PrivateRoute exact path="/AddEvents" component={AddEvents} />
          <PrivateRoute exact path="/UpdateEvents" component={UpdateEvents} />
          {/* <PrivateRoute exact path="/UpdateEvents/:id" component={UpdateEvents} /> */}
          <PrivateRoute exact path="/Articles" component={Articles} />
          <PrivateRoute exact path="/AddArticles" component={AddArticles} />
          <PrivateRoute
            exact
            path="/UpdateArticles"
            component={UpdateArticles}
          />
          <PrivateRoute exact path="/Categories" component={Categories} />
          <PrivateRoute exact path="/Subcategories" component={Subcategories} />
          <PrivateRoute exact path="/Level" component={Levelgroup} />
          <PrivateRoute exact path="/Programmes" component={Programmes} />
          <PrivateRoute exact path="/Tags" component={Tags} />
          <PrivateRoute exact path="/GroupsData" component={GroupsData} />
          <PrivateRoute exact path="/Image" component={Image} />
          <PrivateRoute exact path="/Grouptags" component={Grouptags} />
          <PrivateRoute exact path="/Faqs" component={Faqs} />
          <PrivateRoute exact path="/AddFaqs" component={AddFaqs} />
          <PrivateRoute exact path="/UpdateFaqs" component={UpdateFaqs} />
          <PrivateRoute exact path="/Sections" component={Sections} />
          <PrivateRoute
            exact
            path="/updateSections"
            component={UpdateSections}
          />
          <PrivateRoute exact path="/Product" component={Product} />
          <PrivateRoute
            exact
            path="/Product_Category_Group"
            component={Product_Category_Group}
          />
          <PrivateRoute
            exact
            path="/Product_Categories"
            component={Product_Categories}
          />
          <PrivateRoute exact path="/Info" component={Info} />
          <PrivateRoute exact path="/userole" component={UserRoleManagement} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
