import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import "./subcategories.css";

const UpdatesubcategoriesDetails = (props) => {
  const updatedDataupdatedData = props.updatedData;
  const [subCategory, setSubcategory] = useState("");
  const [disImage, setDisImage] = useState("");
  const [image, setImage] = useState("");
  const [option, setoption] = useState([]);
  const [sendsubcategory, setsendsubcategory] = useState("");
  const reset = () => {
    setsendsubcategory("");
    setImage("");
    setDisImage("");
  };

  function subupdateData() {
    let data = new FormData();
    data.append(
      "categoryId",
      sendsubcategory === "" || null
        ? props.updatedData.categoryId._id
        : sendsubcategory
    );
    data.append("subCategoryId", props.updatedData._id);
    data.append(
      "image",
      image === "" || null ? updatedDataupdatedData?.image : image
    );

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/updateSubcategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        props.subgetnew();
        reset();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  useEffect(() => {
    getnew();
  }, []);

  function handlechange(event) {
    setsendsubcategory(event.value);
  }

  function getnew() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        let array = [];
        response.data.CategoryData?.map((item) => {
          array.push({ value: item._id, label: item.category });
        });
        setoption(array);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  //--------Function to handle and set ico Image--------//

  const IconImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      let display = URL.createObjectURL(file);
      setDisImage(display);
      setImage(event.target.files[0]);
    }
  };

  const renderImages = (image) => {
    return (
      <img
        style={{ width: "110px", height: "140px" }}
        src={image}
        key={image}
      />
    );
  };
  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Edit Category
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={reset}
        ></button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-12 mb-3">
            <label className="form-label">Select Category Group</label>
            <div className="position-relative">
              <Select
                defaultValue={option.map((item) => {
                  if (props.updatedData?.categoryId?.category === item.label) {
                    return {
                      value: props.updatedData?.categoryId?._id,
                      label: props.updatedData?.categoryId?.category,
                    };
                  }
                })}
                key={option.map((item) => {
                  if (props.updatedData?.categoryId?._id === item.value) {
                    return props.updatedData?.categoryId?._id;
                  }
                })}
                options={option}
                onChange={handlechange}
              />
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <label className="form-label"> Category </label>

            <input
              type="text"
              defaultValue={props.updatedData?.subCategory}
              className="form-control"
              disabled
            />
            <div className="hint_box" style={{ display: "block" }}></div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-4">
            <label className="form-label">Category Icon</label>
            <div className="position-relative">
              <ul className="imagesUpload">
                <li style={{ width: "100%", height: "148px" }}>
                  <input type="file" onChange={IconImage} />
                  {!disImage ? (
                    <img
                      style={{ width: "100%", height: "148px" }}
                      src={props.updatedData?.image}
                    />
                  ) : (
                    renderImages(disImage)
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-danger "
          data-bs-dismiss="modal"
          onClick={reset}
        >
          Cancel
        </button>
        <button
          type="submit"
          onClick={() => {
            subupdateData();
          }}
          className="btn submitBtn"
          data-bs-dismiss="modal"
        >
          Save
        </button>
      </div>
    </>
  );
};

export default UpdatesubcategoriesDetails;
