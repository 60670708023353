import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
// import $ from 'jquery';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GlobalContextProvider } from "./store/global-context";
import { CookiesProvider } from "react-cookie";

ReactDOM.render(
  <CookiesProvider>
    <GlobalContextProvider>
      <App />
    </GlobalContextProvider>
  </CookiesProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

ReactDOM.render(<App />, document.getElementById("root"));
