import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import Compressor from "compressorjs";

const Updateimage = (props) => {
  let edithomepagebanner = props.updatedData;
  let defaultObjtype = [
    { value: "0", label: "articles" },
    { value: "1", label: "events" },
    { value: "2", label: "classes" },
    { value: "3", label: "groups" },
    { value: "4", label: "none" },
  ];
  const [ObjtypeSend, setObjtypeSend] = useState("");
  const [ObjectSend, setObjectSend] = useState("");
  let [Obj, setObj] = useState([]);
  const [articleObj, setarticleObj] = useState([]);
  const [eventObj, seteventObj] = useState([]);
  const [classesObj, setclassesObj] = useState([]);
  const [groupsObj, setgroupsObj] = useState([]);
  const [Images, setImages] = useState("");
  const [SendImages, setSendImages] = useState("");

  useEffect(() => {
    if (edithomepagebanner?.objtype === "articles") {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_BASEURL}/getarticles`,
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          let optionArray = [];
          response.data?.articleData?.map((item) => {
            optionArray.push({ value: item._id, label: item.title });
          });
          setObj(optionArray);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }

    if (edithomepagebanner?.objtype === "events") {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_BASEURL}/getevents`,
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          let optionArray = [];
          response.data?.eventData?.map((item) => {
            optionArray.push({ value: item._id, label: item.title });
          });
          setObj(optionArray);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }

    if (edithomepagebanner?.objtype === "classes") {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_BASEURL}/getclass`,
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          let optionArray = [];
          response.data?.data?.map((item) => {
            optionArray.push({ value: item._id, label: item.businessName });
          });
          setObj(optionArray);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }

    if (edithomepagebanner?.objtype === "groups") {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_BASEURL}/getgroups`,
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          let optionArray = [];
          response.data?.data?.map((item) => {
            optionArray.push({ value: item._id, label: item.groups });
          });
          setObj(optionArray);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }, []);

  const handleChange = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          let display = URL.createObjectURL(compressedResult);
          setImages(display);
          setSendImages(compressedResult);
        },
      });
    }
  };

  const resetForm = () => {
    setObjtypeSend("");
    setObjectSend("");
    setSendImages("");
    setImages("");
  };

  const renderImages = (image) => {
    return (
      <img
        style={{ width: "110px", height: "140px" }}
        src={image}
        key={image}
      />
    );
  };

  function handleObjectChange(event) {
    setObjectSend(event.value);
  }

  function handleObjecttypeChange(event) {
    setObj([]);
    let setnewObjType = "";
    if (event.label === "articles") {
      setObj(articleObj);
      setnewObjType = "articles";
    } else if (event.label === "events") {
      setObj(eventObj);
      setnewObjType = "events";
    } else if (event.label === "classes") {
      setObj(classesObj);
      setnewObjType = "classes";
    } else if (event.label === "groups") {
      setObj(groupsObj);
      setnewObjType = "groups";
    } else if (event.label === "none") {
      setObj([]);
      setnewObjType = "none";
    }
    setObjtypeSend(setnewObjType);
  }

  useEffect(() => {
    articleObject();
    eventsObject();
    classesObject();
    groupsObject();
  }, []);

  const articleObject = () => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getarticles`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let optionArray = [];
        response.data?.articleData?.map((item) => {
          optionArray.push({ value: item._id, label: item.title });
        });
        setarticleObj(optionArray);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const eventsObject = () => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getevents`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let optionArray = [];
        response.data?.eventData?.map((item) => {
          optionArray.push({ value: item._id, label: item.title });
        });
        seteventObj(optionArray);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const classesObject = () => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getclass`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let optionArray = [];
        response.data?.data?.map((item) => {
          optionArray.push({ value: item._id, label: item.businessName });
        });
        setclassesObj(optionArray);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const groupsObject = () => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getgroups`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let optionArray = [];
        response.data?.data?.map((item) => {
          optionArray.push({ value: item._id, label: item.groups });
        });
        setgroupsObj(optionArray);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  function editHomepageBanner() {
    let data = new FormData();
    data.append("homepagebannerId", edithomepagebanner.bannerdataId);
    data.append("page", edithomepagebanner.page);
    data.append("section", edithomepagebanner.section);
    data.append("type", edithomepagebanner.application);
    data.append(
      "objtype",
      ObjtypeSend == ""
        ? edithomepagebanner.objtype == undefined
          ? "none"
          : edithomepagebanner.objtype
        : ObjtypeSend
    );
    data.append(
      "image",
      SendImages == "" ? edithomepagebanner.image : SendImages
    );
    data.append(
      "Obj",
      ObjectSend == "" ? edithomepagebanner.Obj._id : ObjectSend
    );

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/editHomepageBanner`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        props.homepagebanner();
        resetForm();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  return (
    <>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Edit Images
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={resetForm}
          ></button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-12 mb-4">
              <label className="form-label">Serial No</label>
              <div className="position-relative">
                <input
                  value={props.rowIndex + 1}
                  type="text"
                  className="form-control"
                  disabled
                />
                <div className="hint_box" style={{ display: "block" }}></div>
              </div>
            </div>
            <div className="col-md-12 mb-4">
              <label className="form-label">Page</label>
              <div className="position-relative">
                <input
                  defaultValue={edithomepagebanner.page}
                  type="text"
                  className="form-control"
                  disabled
                />
                <div className="hint_box" style={{ display: "block" }}></div>
              </div>
            </div>
            <div className="col-md-12 mb-4">
              <label className="form-label">Section</label>
              <div className="position-relative">
                <input
                  defaultValue={edithomepagebanner.section}
                  type="text"
                  className="form-control"
                  disabled
                />
                <div className="hint_box" style={{ display: "block" }}></div>
              </div>
            </div>
            <div className="col-md-12 mb-4">
              <label className="form-label">Image Box</label>

              <ul className="imagesUpload">
                <li style={{ width: "100%", height: "128px" }}>
                  <input type="file" onChange={handleChange} />
                  {!Images ? (
                    <img
                      style={{ width: "100%", height: "128px" }}
                      src={edithomepagebanner.image}
                    />
                  ) : (
                    renderImages(Images)
                  )}
                  {/* {renderImages(articleImage)} */}
                </li>
              </ul>
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">Application</label>
              <div className="App">
                <input
                  defaultValue={edithomepagebanner.application}
                  type="text"
                  className="form-control"
                  disabled
                />
              </div>
            </div>
            <div className="col-md-12 mb-4">
              <label className="form-label"> Object-Type</label>
              <div className="position-relative">
                <Select
                  defaultValue={defaultObjtype.map((item) => {
                    if (
                      edithomepagebanner?.objtype === "articles" &&
                      item.label === "articles"
                    ) {
                      return item;
                    } else if (
                      edithomepagebanner?.objtype === "events" &&
                      item.label === "events"
                    ) {
                      return item;
                    } else if (
                      edithomepagebanner?.objtype === "classes" &&
                      item.label === "classes"
                    ) {
                      return item;
                    } else if (
                      edithomepagebanner?.objtype === "groups" &&
                      item.label === "groups"
                    ) {
                      return item;
                    } else if (
                      edithomepagebanner?.objtype === "none" &&
                      item.label === "none"
                    ) {
                      return item;
                    }
                  })}
                  key={defaultObjtype.map((item) => {
                    if (
                      edithomepagebanner?.objtype === "articles" &&
                      item.label === "articles"
                    ) {
                      return item.value;
                    } else if (
                      edithomepagebanner?.objtype === "events" &&
                      item.label === "events"
                    ) {
                      return item.value;
                    } else if (
                      edithomepagebanner?.objtype === "classes" &&
                      item.label === "classes"
                    ) {
                      return item.value;
                    } else if (
                      edithomepagebanner?.objtype === "groups" &&
                      item.label === "groups"
                    ) {
                      return item.value;
                    } else if (
                      edithomepagebanner?.objtype === "none" &&
                      item.label === "none"
                    ) {
                      return item.value;
                    }
                  })}
                  options={defaultObjtype}
                  onChange={handleObjecttypeChange}
                />
                <div className="hint_box" style={{ display: "block" }}></div>
              </div>
            </div>
            {/* <div className="col-md-12 mb-4">
              <label className="form-label">Object</label>
              <div className="position-relative">
                <Select
                  defaultValue={defaultObjtype.map((item) => {
                    if (edithomepagebanner?.objtype === "articles") {
                      return {
                        value: edithomepagebanner?.Obj
                          ? edithomepagebanner?.Obj._id
                          : "",
                        label: edithomepagebanner?.Obj.title,
                      };
                    } else if (edithomepagebanner?.objtype === "events") {
                      return {
                        value: edithomepagebanner?.Obj
                          ? edithomepagebanner?.Obj._id
                          : "",
                        label: edithomepagebanner?.Obj.title,
                      };
                    } else if (edithomepagebanner?.objtype === "classes") {
                      return {
                        value: edithomepagebanner?.Obj
                          ? edithomepagebanner?.Obj._id
                          : "",
                        label: edithomepagebanner.Obj?.businessName,
                      };
                    } else if (edithomepagebanner?.objtype === "groups") {
                      return {
                        value: edithomepagebanner?.Obj
                          ? edithomepagebanner?.Obj._id
                          : "",
                        label: edithomepagebanner.Obj?.groups,
                      };
                    }
                  })}
                  key={defaultObjtype.map((item) => {
                    if (edithomepagebanner?.objtype === "articles") {
                      return edithomepagebanner?.Obj
                        ? edithomepagebanner?.Obj._id
                        : "";
                    } else if (edithomepagebanner?.objtype === "events") {
                      return edithomepagebanner?.Obj
                        ? edithomepagebanner?.Obj._id
                        : "";
                    } else if (edithomepagebanner?.objtype === "classes") {
                      return edithomepagebanner?.Obj
                        ? edithomepagebanner?.Obj._id
                        : "";
                    } else if (edithomepagebanner?.objtype === "groups") {
                      return edithomepagebanner?.Obj
                        ? edithomepagebanner?.Obj._id
                        : "";
                    }
                  })}
                  options={Obj}
                  onFocus={() => {
                    if (edithomepagebanner?.objtype === "articles") {
                      Obj = articleObj;
                    } else if (edithomepagebanner?.objtype === "events") {
                      Obj = eventObj;
                    } else if (edithomepagebanner?.objtype === "classes") {
                      Obj = classesObj;
                    } else if (edithomepagebanner?.objtype === "groups") {
                      Obj = groupsObj;
                    }
                  }}
                  onChange={handleObjectChange}
                />
                <div className="hint_box" style={{ display: "block" }}></div>
              </div>
            </div> */}
            {/* 2 Solution */}
            <div className="col-md-12 mb-4">
              <label className="form-label">Object</label>
              <div className="position-relative">
                <Select
                  defaultValue={Obj.map((item) => {
                    if (item.value == edithomepagebanner?.Obj._id) {
                      return { value: item.value, label: item.label };
                    }
                  })}
                  key={Obj.map((item) => {
                    if (item.value == edithomepagebanner?.Obj._id) {
                      return item.value;
                    }
                  })}
                  options={Obj}
                  onFocus={() => {
                    if (edithomepagebanner?.objtype === "articles") {
                      Obj = articleObj;
                    } else if (edithomepagebanner?.objtype === "events") {
                      Obj = eventObj;
                    } else if (edithomepagebanner?.objtype === "classes") {
                      Obj = classesObj;
                    } else if (edithomepagebanner?.objtype === "groups") {
                      Obj = groupsObj;
                    }
                  }}
                  onChange={handleObjectChange}
                />
                <div className="hint_box" style={{ display: "block" }}></div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn CancelBtn"
            data-bs-dismiss="modal"
            onClick={resetForm}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              editHomepageBanner();
            }}
            type="button"
            className="btn submitBtn"
            data-bs-dismiss="modal"
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};
export default Updateimage;
