import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import "../../loader.js";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { AiOutlinePoweroff } from "react-icons/ai";
import { AiFillSetting } from "react-icons/ai";
import { BiHelpCircle } from "react-icons/bi";
import { modules } from "../UserRoleManagement/roles.js";

function Sidebar() {
  let localdata = JSON.parse(sessionStorage.getItem("userData"));
  const [roles, setRoles] = useState([]);
  const [adminType, setAdminType] = useState("");
  useEffect(() => {
    const timer = setTimeout(() => {
      setRoles(localdata?.roles);
      setAdminType(localdata?.type);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const updatedroles = roles?.map((item) => {
    const mapdata = [];
    modules.forEach((module, index) => {
      if (item === module.value) {
        mapdata.push(module);
      }
    });
    return mapdata;
  });
  const flattened = updatedroles?.flatMap((num) => num);

  return (
    <aside className="left-sidebar col-md-4">
      <div className="scroll-sidebar">
        <nav className="sidebar-nav">
          <ul id="sidebarnav">
            <h5>Menus</h5>
            {flattened?.map((moduleItem, moduleIndex) => {
              return (
                <li key={moduleIndex} className="sidebar-item">
                  <NavLink
                    className="sidebar-link waves-effect waves-dark sidebar-link"
                    to={moduleItem.link}
                    aria-expanded="false"
                  >
                    {moduleItem.icon}
                    <span className="hide-menu">{moduleItem.label}</span>
                  </NavLink>
                </li>
              );
            })}

            {adminType === "master admin" && (
              <>
                {modules?.map((moduleItem, moduleIndex) => {
                  return (
                    <li key={moduleIndex} className="sidebar-item">
                      <NavLink
                        className="sidebar-link waves-effect waves-dark sidebar-link"
                        to={moduleItem.link}
                        aria-expanded="false"
                      >
                        {moduleItem.icon}
                        <span className="hide-menu">{moduleItem.label}</span>
                      </NavLink>
                    </li>
                  );
                })}
                <li className="sidebar-item">
                  <NavLink
                    className="sidebar-link waves-effect waves-dark sidebar-link"
                    to="/userole"
                    aria-expanded="false"
                  >
                    <AiOutlineInfoCircle />
                    <span className="hide-menu">Role Management</span>
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
      <div className="sidebar-footer">
        <NavLink to="/" className="link" title="Settings">
          <AiFillSetting />
        </NavLink>
        <NavLink to="/" className="link" title="Help Resources">
          <BiHelpCircle />
        </NavLink>
        <NavLink to="/" className="link" title="Logout">
          <AiOutlinePoweroff />
        </NavLink>
      </div>
    </aside>
  );
}

export default Sidebar;
