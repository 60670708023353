//This Component is used to Render Images on the screen and can be used while uploading images

import ImageUploader from "react-images-upload";

function RenderImages(props) {
  let onDrop = (pictureFiles, pictureDataURLs) => {
    const newImagesUploaded = pictureDataURLs.slice(
      props.defaultImages?.length
    );
    props.handleChange(pictureFiles, newImagesUploaded, pictureDataURLs);
  };
  return (
    <ImageUploader
      withIcon={false}
      withLabel={false}
      withPreview={true}
      buttonText={"Add photos"}
      fileSizeError={"File size is too big!"}
      fileTypeError={"This extension is not supported!"}
      onChange={onDrop}
      imgExtension={props.imgExtension}
      maxFileSize={props.maxFileSize}
      defaultImages={props.defaultImages}
    />
  )
}

export default RenderImages;