import { AiFillAppstore } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { AiOutlinePoweroff } from "react-icons/ai";
import { AiFillSetting } from "react-icons/ai";
import { BiHelpCircle } from "react-icons/bi";
import { FaList } from "react-icons/fa";
import { AiFillTags } from "react-icons/ai";
import { AiOutlineBranches } from "react-icons/ai";
import { BiImages } from "react-icons/bi";

export const Role = {
  ADMIN: "Admin",
  SUBADMIN: "Subadmin",
};

export const modules = [
  {
    value: "dashboard",
    label: "Dashboard",
    link: "/dashboard",
    icon: <AiFillAppstore />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
  },
  {
    value: "user",
    label: "User",
    link: "/Students",
    icon: <FaUsers />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
  },
  {
    value: "business",
    label: "Business",
    link: "/Business",
    icon: <FaUsers />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
  },
  {
    value: "branch",
    label: "Branch",
    link: "/Branch",
    icon: <AiOutlineBranches />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
  },
  {
    value: "programmes",
    label: "Programmes",
    link: "/Programmes",
    icon: <AiOutlineBranches />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
  },
  {
    value: "events",
    label: "Events",
    link: "/Events",
    icon: <FaUsers />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
  },
  {
    value: "articles",
    label: "Articles",
    link: "/Articles",
    icon: <FaUsers />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
  },
  {
    value: "image",
    label: "Image",
    link: "/Image",
    icon: <BiImages />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
  },
  {
    value: "category_group",
    label: "Subject Group",
    link: "/Categories",
    icon: <FaList />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
  },
  {
    value: "category",
    label: "Subject",
    link: "/Subcategories",
    icon: <FaList />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
  },
  {
    value: "level_group",
    label: "Category",
    link: "/Level",
    icon: <FaList />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
  },
  // {
  //     value: 'level',
  //     label: 'Level',
  //     link: "/Level",
  //     icon: <FaList />,
  //     isAdding: true,
  //     isEditing: true,
  //     isDeleting: true,
  //     isViewing: true,
  //     isDisabled: false,
  //     isPermission: false,
  //     isVerify: false,
  //     isShow: true,
  //     isHide: false
  // },
  {
    value: "tags",
    label: "Tags",
    link: "/Tags",
    icon: <AiFillTags />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
  },
  {
    value: "groups",
    label: "Groups",
    link: "/GroupsData",
    icon: <AiFillTags />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
  },
  {
    value: "faqs",
    label: "FAQ's",
    link: "/Faqs",
    icon: <AiFillTags />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
  },
  {
    value: "tag_group",
    label: "Tag Group",
    link: "/Grouptags",
    icon: <AiFillTags />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
  },
  {
    value: "product",
    label: "Product",
    link: "/Product",
    icon: <AiFillTags />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
  },
  {
    value: "product_categories",
    label: "Product Categories",
    link: "/Product_Categories",
    icon: <AiFillTags />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
  },
  {
    value: "sections",
    label: "Sections",
    link: "/Sections",
    icon: <AiFillSetting />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
  },
  {
    value: "information_text",
    label: "Information Text",
    link: "/Info",
    icon: <AiOutlineInfoCircle />,
    isAdding: true,
    isEditing: true,
    isDeleting: true,
    isViewing: true,
    isDisabled: false,
    isPermission: false,
    isVerify: false,
    isShow: true,
    isHide: false,
  },
];

export const subModules = [
  {
    value: "home",
    label: "Home",
  },
  {
    value: "main_office_address",
    label: "Main Office Address",
  },
  {
    value: "business_information",
    label: "Business Information",
  },
  {
    value: "branch_location",
    label: "Branch & Location",
  },
  {
    value: "programmes",
    label: "Programmes",
  },
  {
    value: "teachers",
    label: "Teachers",
  },
  {
    value: "photos",
    label: "Photos",
  },
  {
    value: "marketplace",
    label: "Marketplace",
  },
  {
    value: "reviews",
    label: "Reviews",
  },
  {
    value: "inbox_leads",
    label: "Inbox / Leads",
  },
  {
    value: "faqs",
    label: "FAQs",
  },
  {
    value: "notes",
    label: "Notes",
  },
];

export const isDownload = [
  {
    value: "addNewUser",
    label: "Add New User",
  },
  {
    value: "uploadUsers",
    label: "Upload Users",
  },
  {
    value: "downloadUsers",
    label: "Download Users",
  },
  {
    value: "addNewBusiness",
    label: "Add New Business",
  },
  {
    value: "uploadBusinesses",
    label: "Upload Businesses",
  },
  {
    value: "updateBusinesses",
    label: "Update Businesses",
  },
  {
    value: "downloadBusinesses",
    label: "Download Businesses",
  },
  {
    value: "uploadBranch",
    label: "Upload Branch",
  },
  {
    value: "downloadBranch",
    label: "Download Branch",
  },
  {
    value: "uploadProgramme",
    label: "Upload Programme",
  },
  {
    value: "downloadProgramme",
    label: "Download Programme",
  },
  {
    value: "uploadEvents",
    label: "Upload Events",
  },
  {
    value: "downloadEvents",
    label: "Download Events",
  },

  {
    value: "downloadCategory",
    label: "Download Category",
  },
  {
    value: "downloadTags",
    label: "Download Tags",
  },
  {
    value: "downloadFAQs",
    label: "Download FAQs",
  },
];
