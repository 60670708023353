import React, { useCallback, useState, useMemo, useEffect } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./Business.css";
import "../Students/Students.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { useLocation } from "react-router-dom";

const nameValueGetter = (params) => {
  return params?.data?.name;
};

const emailValueGetter = (params) => {
  return params?.data?.email;
};

const mobileNoValueGetter = (params) => {
  return params?.data?.mobileNo;
};

function DuplicateBusiness() {
  let location = useLocation();
  const [rowData, setRowData] = useState([]);
  const rowHeight = 55;

  useEffect(() => {
    let Categorydata = [];
    let DuplicateBussinesname = [];
    let DuplicateEmails = [];
    let Groupsdata = [];
    let email_alreay_exist = [];
    let leveldata = [];
    let tagdata = [];
    if (location && location.state && location.state.details) {
      location.state.details.Categorydata.forEach((element) => {
        Categorydata.push({ ...element, errorName: "Category data" });
      });
      location.state.details.DuplicateBussinesname.forEach((element) => {
        DuplicateBussinesname.push({
          ...element,
          errorName: "Duplicate Bussines Name",
        });
      });
      location.state.details.DuplicateEmails.forEach((element) => {
        DuplicateEmails.push({ ...element, errorName: "Duplicate Emails" });
      });
      location.state.details.Groupsdata.forEach((element) => {
        Groupsdata.push({ ...element, errorName: "Groups data" });
      });
      location.state.details.email_alreay_exist.forEach((element) => {
        email_alreay_exist.push({
          ...element,
          errorName: "Email Already Exist",
        });
      });
      location.state.details.leveldata.forEach((element) => {
        leveldata.push({ ...element, errorName: "Level data" });
      });
      location.state.details.tagdata.forEach((element) => {
        tagdata.push({ ...element, errorName: "Tags data" });
      });
    }

    setRowData([
      ...Categorydata,
      ...DuplicateBussinesname,
      ...DuplicateEmails,
      ...Groupsdata,
      ...email_alreay_exist,
      ...leveldata,
      ...tagdata,
    ]);
  }, []);

  const sendToClipboard = useCallback((params) => {
    // console.log(params.data);
  }, []);

  const onCellValueChanged = useCallback((params) => {
    // console.log("Callback onCellValueChanged:", params);
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      // flex: 1,
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);

  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    }
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };

  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Duplicate Business Data
                <div className="float-end btns_head">
                  {/* <JsonDownload title="Download Excel" fileName="business-excel" downloadfile={businessBulkUploadData} btnClassName={"btn btn-theme btn-sm"} btnColor={"#f9560f"} /> */}
                </div>
              </h4>

              <div
                style={{ width: "100%" }}
                className="ag-theme-alpine tableFix"
              >
                <AgGridReact
                  style={{ width: "100%", height: "100%;" }}
                  rowHeight={rowHeight}
                  rowData={rowData}
                  defaultColDef={defaultColDef}
                  enableRangeSelection={true}
                  rowSelection={"multiple"}
                  enableCellTextSelection={true}
                  ensureDomOrder={true}
                  copySelectedRowsToClipboard={true}
                  copyHeadersToClipboard={true}
                  copySelectedRangeToClipboard={true}
                  sendToClipboard={sendToClipboard}
                  onCellValueChanged={onCellValueChanged}
                  // domLayout="autoHeight"
                >
                  <AgGridColumn
                    width={90}
                    field="sr"
                    sortable={false}
                    filter={false}
                  ></AgGridColumn>

                  <AgGridColumn
                    width={150}
                    field="errorName"
                    sortable={false}
                    filter={false}
                  ></AgGridColumn>

                  <AgGridColumn
                    width={100}
                    field="name"
                    valueGetter={nameValueGetter}
                    sortable={true}
                    comparator={customLowerCaseComparator}
                  ></AgGridColumn>

                  <AgGridColumn
                    field="businessName"
                    width={150}
                    sortable={true}
                    comparator={customLowerCaseComparator}
                  ></AgGridColumn>

                  <AgGridColumn
                    width={300}
                    field="email"
                    cellRenderer="businessEmailRenderer"
                    valueGetter={emailValueGetter}
                    sortable={true}
                    comparator={customLowerCaseComparator}
                  ></AgGridColumn>

                  <AgGridColumn
                    field="mobileNo"
                    cellRenderer="mobileNoRenderer"
                    valueGetter={mobileNoValueGetter}
                    sortable={true}
                    comparator={customLowerCaseComparator}
                  ></AgGridColumn>
                  <AgGridColumn
                    field="tags"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={false}
                  ></AgGridColumn>

                  <AgGridColumn
                    field="level"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={false}
                  ></AgGridColumn>
                  <AgGridColumn
                    field="groupsName"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={false}
                  ></AgGridColumn>

                  <AgGridColumn
                    field="ageto"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={false}
                  ></AgGridColumn>
                  <AgGridColumn
                    field="agefrom"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={false}
                  ></AgGridColumn>
                  <AgGridColumn
                    field="webAddress"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={false}
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="class Type"
                    field="sitetype"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={false}
                  ></AgGridColumn>

                  <AgGridColumn
                    headerName="Category"
                    field="businesssubCategory"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={false}
                  ></AgGridColumn>

                  <AgGridColumn
                    field="gender"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={false}
                  ></AgGridColumn>

                  <AgGridColumn
                    field="country"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={false}
                  ></AgGridColumn>

                  <AgGridColumn
                    field="DOB"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={false}
                  ></AgGridColumn>
                  <AgGridColumn
                    headerName="address"
                    field="address2"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={false}
                  ></AgGridColumn>
                  <AgGridColumn
                    field="aboutBusiness"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={false}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default DuplicateBusiness;
