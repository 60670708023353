import React, { useState, useEffect, useHistory, useRef } from "react";
import axios from "axios";
import JoditEditor from "jodit-react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

const Updateinfo = (props) => {
  const defaultFonts = [
    "Arial",
    "Comic Sans MS",
    "Courier New",
    "Impact",
    "Georgia",
    "Tahoma",
    "Trebuchet MS",
    "Verdana",
  ];
  const sortedFontOptions = [
    "Logical",
    "Salesforce Sans",
    "Garamond",
    "Sans-Serif",
    "Serif",
    "Times New Roman",
    "Helvetica",
    ...defaultFonts,
  ].sort();
  const editor = useRef(null);
  let editinfosection = props.updatedData;

  const [description, setdescription] = useState("");
  const [Link, setLink] = useState(editinfosection.Link);
  const config = {
    readonly: false,
    height: 400,
  };

  const reset = () => {
    setdescription("");
    setLink("");
  };

  useEffect(() => {
    if (editinfosection?.description?.length > 0) {
      setdescription(editinfosection.description);
    }
    if (editinfosection?.Link?.length > 0) {
      setLink(editinfosection?.Link);
    }
  }, [editinfosection]);

  function editinfo() {
    let data = JSON.stringify({
      infosectionId: editinfosection._id,
      description:
        description === "" ? editinfosection.description : description,
      Link: Link === "" ? editinfosection.Link : Link,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/editinfosection`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        props.getinfosection();
        reset();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  return (
    <>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Edit Information
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={reset}
          ></button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-12 mb-4">
              <label className="form-label">Page</label>
              <div className="position-relative">
                <input
                  value={editinfosection.page}
                  type="text"
                  className="form-control"
                  disabled
                />
                <div className="hint_box" style={{ display: "block" }}></div>
              </div>
            </div>
            <div className="col-md-12 mb-4">
              <label className="form-label">Section</label>
              <div className="position-relative">
                <input
                  defaultValue={editinfosection.section}
                  key={editinfosection.section}
                  type="text"
                  className="form-control"
                  disabled
                />
                <div className="hint_box" style={{ display: "block" }}></div>
              </div>
            </div>
            <div className="col-md-12 mb-4">
              <label className="form-label">Textbox Sr No.</label>
              <div className="position-relative">
                <input
                  value={editinfosection.number}
                  type="number"
                  className="form-control"
                  disabled
                />
                <div className="hint_box" style={{ display: "block" }}></div>
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">Description</label>
              {/* <div className="App">
                <div className="col-md-12 mb-4">
                  <div className="position-relative">
                    <JoditEditor
                      ref={editor}
                      value={editinfosection.description}
                      config={config}
                      tabIndex={1}
                      onBlur={(newContent) => setdescription(newContent)}
                    />
                  </div>
                </div>
              </div> */}
              <div className="App">
                <div className="col-md-12 mb-4">
                  <div className="position-relative">
                    <SunEditor
                      width="100%"
                      plugin=""
                      height="100%"
                      setContents={description}
                      // defaultValue={description}
                      onChange={(event) => setdescription(event)}
                      setOptions={{
                        buttonList: [
                          ["undo", "redo"],
                          ["font", "fontSize", "formatBlock"],
                          ["paragraphStyle", "blockquote"],
                          [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "subscript",
                            "superscript",
                          ],
                          ["fontColor", "hiliteColor"],
                          ["align", "list", "lineHeight"],
                          ["outdent", "indent"],
                          ["fullScreen", "showBlocks", "codeView"],
                          ["preview", "print"],
                          ["removeFormat"],

                          [
                            ":i-More Misc-default.more_vertical",
                            "image",
                            "video",
                            "audio",
                            "link",
                            "fullScreen",
                            "showBlocks",
                            "codeView",
                            "preview",
                            "print",
                            "save",
                            "template",
                          ],
                          [
                            ":r-More Rich-default.more_plus",
                            "table",
                            "horizontalRule",
                            "imageGallery",
                          ],
                        ],
                        defaultTag: "div",
                        minHeight: "300px",
                        showPathLabel: false,
                        font: sortedFontOptions,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 mb-4">
                <label className="form-label">Link</label>
                <div className="position-relative">
                  <input
                    onChange={(event) => setLink(event.target.value)}
                    value={Link}
                    type="text"
                    className="form-control"
                  />
                  <div className="hint_box" style={{ display: "block" }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn CancelBtn"
            data-bs-dismiss="modal"
            onClick={reset}
          >
            Cancel
          </button>
          <button
            onClick={editinfo}
            type="button"
            className="btn submitBtn"
            data-bs-dismiss="modal"
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};
export default Updateinfo;
