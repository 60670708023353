import React, { useState, useMemo, useEffect, useRef } from "react";
import axios from "axios";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import JoditEditor from "jodit-react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

function UpdateFaqs() {
  const defaultFonts = [
    "Arial",
    "Comic Sans MS",
    "Courier New",
    "Impact",
    "Georgia",
    "Tahoma",
    "Trebuchet MS",
    "Verdana",
  ];
  const sortedFontOptions = [
    "Logical",
    "Salesforce Sans",
    "Garamond",
    "Sans-Serif",
    "Serif",
    "Times New Roman",
    "Helvetica",
    ...defaultFonts,
  ].sort();
  const [serviceList, setServiceList] = useState([]);
  const [optionsgroup, setoptionsgroup] = useState([]);
  const [groupdata, setGroupdata] = useState("");
  const [classId, setClassId] = useState("");
  const [groupId, setGroupId] = useState("");
  const [deleteIndex, setDeleteIndex] = useState("");

  let location = useLocation();
  let history = useHistory();
  const editor = useRef(null);
  const config = {
    readonly: false,
    height: 400,
  };
  const reset = () => {
    setServiceList([]);
  };

  useEffect(() => {
    getFaqs();
    setId(location.state.details);
    setServiceList(location.state.details.faqData);
  }, []);

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };

  const handleServiceAdd = () => {
    setServiceList([
      ...serviceList,
      { sequenceNumber: "", question: "", answer: "" },
    ]);
  };

  //----------FUNCTION TO SET GROUP OR CLASS ID------------//
  function setId(details) {
    if (details.groupId === undefined) {
      setClassId(details.classId._id);
    } else {
      setGroupId(details.groupId._id);
    }
  }

  useEffect(() => {
    getnewgroup();
  }, []);
  //----------FAQ UPDATE API----------//

  function updateFaqs() {
    let data = JSON.stringify({
      FAQId: location.state.details._id,
      groupId: groupId,
      classId: classId,
      faqData: serviceList,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/editFAQ`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        history.push("./Faqs");
        reset();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  //----------DELETE SINGLE FAQ API----------//

  function deleteSingleFaq() {
    let axios = require("axios");
    let data = JSON.stringify({
      FAQId: location.state.details._id,
      faqdataIndex: deleteIndex,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deletefaqonIndex`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {})
      .catch(function (error) {
        // console.log(error);
      });
  }

  //----------FAQ GET API------------//

  function getFaqs() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getfaq`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {})
      .catch(function (error) {
        // console.log(error);
      });
  }

  // ----multi group selete get--//

  function getnewgroup() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getgroups`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let optionsgroup = [];
        response.data.categoryData.forEach((grup, index) => {
          optionsgroup.push({ label: grup.groups, value: grup._id });
        });
        setoptionsgroup(optionsgroup);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Edit FAQ's</h4>
              <div className="branchData">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Select Type</label>
                    <input
                      type="text"
                      defaultValue="Group"
                      className="form-control"
                      disabled
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Select Group</label>
                    <Select
                      defaultValue={
                        location.state.details.groupId === undefined
                          ? {
                              // value: location.state.details.classId._id,
                              // label:
                              //   location.state.details.classId.businessName,
                            }
                          : {
                              value: location.state.details.groupId._id,
                              label: location.state.details.groupId.groups,
                            }
                      }
                      isDisabled={true}
                    />
                  </div>
                  {serviceList?.map((singleService, index) => (
                    <div>
                      <div className="faqButton">
                        <div className="actionBtn">
                          <button
                            type="button"
                            onClick={() => {
                              setDeleteIndex(index);
                              handleServiceRemove(index);
                              deleteSingleFaq(index);
                            }}
                            className="btn btn-danger"
                          >
                            <span>DELETE</span>
                          </button>

                          {serviceList.length - 1 === index &&
                            serviceList.length < 4000 && (
                              <button
                                type="button"
                                onClick={handleServiceAdd}
                                className="btn btn-success"
                              >
                                <span>+</span>
                              </button>
                            )}

                          {serviceList.length !== 1 &&
                            serviceList.length - 1 === index && (
                              <button
                                type="button"
                                onClick={() => handleServiceRemove(index)}
                                className="btn btn-danger"
                              >
                                <span>-</span>
                              </button>
                            )}
                        </div>
                        <div className="col-md-12 mt-3">
                          <label className="form-label">Sequence Number</label>
                          <input
                            defaultValue={singleService.sequenceNumber}
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              singleService.sequenceNumber = e.target.value;
                            }}
                          />
                        </div>
                        <div className="col-md-12 mt-3">
                          <label className="form-label">Question</label>
                          <textarea
                            type="textarea"
                            defaultValue={singleService.question}
                            className="form-control"
                            onChange={(e) => {
                              singleService.question = e.target.value;
                            }}
                          />
                        </div>

                        <div className="col-md-12 mt-3">
                          <label className="form-label">Answer</label>

                          <div className="App">
                            <SunEditor
                              width="100%"
                              plugin=""
                              height="100%"
                              setContents={singleService.answer}
                              onChange={(newContent) => {
                                singleService.answer = newContent;
                              }}
                              setOptions={{
                                buttonList: [
                                  ["undo", "redo"],
                                  ["font", "fontSize", "formatBlock"],
                                  ["paragraphStyle", "blockquote"],
                                  [
                                    "bold",
                                    "underline",
                                    "italic",
                                    "strike",
                                    "subscript",
                                    "superscript",
                                  ],
                                  ["fontColor", "hiliteColor"],
                                  ["align", "list", "lineHeight"],
                                  ["outdent", "indent"],
                                  ["fullScreen", "showBlocks", "codeView"],
                                  ["preview", "print"],
                                  ["removeFormat"],

                                  [
                                    ":i-More Misc-default.more_vertical",
                                    "image",
                                    "video",
                                    "audio",
                                    "link",
                                    "fullScreen",
                                    "showBlocks",
                                    "codeView",
                                    "preview",
                                    "print",
                                    "save",
                                    "template",
                                  ],
                                  [
                                    ":r-More Rich-default.more_plus",
                                    "table",
                                    "horizontalRule",
                                    "imageGallery",
                                  ],
                                ],
                                defaultTag: "div",
                                minHeight: "300px",
                                showPathLabel: false,
                                font: sortedFontOptions,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="col-md-12 mt-3">
                    <button
                      type="button"
                      className="btn CancelBtn me-3 "
                      onClick={() => {
                        history.push("./Faqs");
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn submitBtn me-3"
                      onClick={updateFaqs}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default UpdateFaqs;
