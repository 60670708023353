import { Component } from "react";
import { JsonToExcel } from "react-json-to-excel";

export default class JsonDownload extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <JsonToExcel
                btnClassName={this.props.btnClassName}
                title={this.props.title}
                data={this.props.downloadfile}
                fileName={this.props.fileName}
                btnColor={this.props.btnColor}
            />
        );
    }
}
