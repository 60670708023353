import React, { useState, useMemo, useEffect, useRef } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import axios from "axios";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./Events.css";
import "./UpdateEvents.css";
import "../Students/Students.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { useHistory } from "react-router-dom";
import ToggleButton from "../../components/Toggle_Button/ToggleButton";
import Modal_Popup from "../../components/modal_renderer/Modal_Popup";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import LoadingSpinner from "../../components/loader";
import JsonDownload from "../../components/downloadjson";
import isdownloadUtils from "../../components/utilDownloadRoles";

const SrNoRenderer = (props) => {
  return <span>{props.node.rowIndex + 1}</span>;
};

const ImageRenderer = (props) => {
  return (
    <span className="profle_img_box">
      <img className="profile_img_table" src={props.data.image[0]} alt="icon" />
    </span>
  );
};

const TitleRenderer = (props) => {
  return <span>{props.data.title}</span>;
};

const Priorityrender = (props) => {
  return <span>{props.data.priority}</span>;
};

const EventTyperender = (props) => {
  return <span>{props.data.seteventtype}</span>;
};

const GroupRender = (props) => {
  return (
    <span className="profle_img_box">
      <span>
        {props?.data?.groups_Id.map((item) => item.grpName)?.join(", ")}
      </span>
    </span>
  );
};

const TagEventRenderer = (props) => {
  return (
    <span className="profle_img_box">
      <span>{props?.data?.tags?.join(", ")}</span>
    </span>
  );
};

const DateRenderer = (props) => {
  return <span>{props.data.Date}</span>;
};

const EndDateRenderer = (props) => {
  return <span>{props.data.endDate}</span>;
};

function Events() {
  const [rowData, setRowData] = useState([]);
  const gridRef = useRef();
  const [DeleteEventsId, setDeleteEventsId] = useState("");
  let history = useHistory();
  const rowHeight = 55;
  const token = `${sessionStorage.getItem("token")}`;
  let encoded = encodeURIComponent(token);
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  });

  useEffect(() => {
    getevents();
  }, []);

  // ------------ Bulk Upload Events api Starts ------------------ //

  const {
    register: uploadRegister,
    unregister,
    handleSubmit: handlesubmitUpload,
    formState: { errors: uploaderrors },
    reset,
    watch,
  } = useForm({ mode: "onBlur" });
  const [uploadIsOpen, setUploadIsOpen] = useState(false);
  const [uplaodStatus, setUplaodStatus] = useState({
    status: false,
    value: "",
  });
  const [uploadIsOpenStatusModal, setUploadIsOpenStatusModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [email_alreay_exist, setEmail_alreay_exist] = useState([]);
  const [NotMatchedGroupdata, setNotMatchedGroupdata] = useState([]);
  const [NotMatchedTagdata, setNotMatchedTagdata] = useState([]);

  function somefun() {
    setUploadIsOpen(false);
    setEmail_alreay_exist([]);
    setNotMatchedGroupdata([]);
    setNotMatchedTagdata([]);
    setLoader(false);
    cross();
  }

  function fun() {
    setLoader(false);
    setUploadIsOpenStatusModal(false);
    setEmail_alreay_exist([]);
    setNotMatchedGroupdata([]);
    setNotMatchedTagdata([]);
    setUploadIsOpen(false);
    cross();
  }

  function cross() {
    setLoader(false);
    setEmail_alreay_exist([]);
    setNotMatchedGroupdata([]);
    setNotMatchedTagdata([]);
    reset();
  }

  function uploadBusinessFiles(postdata) {
    setLoader(true);
    let data = new FormData();
    data.append("attachment2", postdata.uploadFile[0]);
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/eventUpload`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        reset();
        getevents();
        setUploadIsOpen(false);
        setUploadIsOpenStatusModal(true);
        setUplaodStatus({
          status: response.data.status,
          value: response.data.message,
        });
        if (response.data.status)
          setTimeout(() => {
            setEmail_alreay_exist(
              response.data.alreadyEmails.filter((item) => item != null)
            );
            setNotMatchedGroupdata(
              response.data.NotMatchedGroupdata.filter((item) => item != null)
            );
            setNotMatchedTagdata(
              response.data.NotMatchedTagdata.filter((item) => item != null)
            );
          }, 1000);

        setTimeout(() => {
          setUploadIsOpen(false);
        }, 1500);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // ----Delete API ---//

  function eventsdeleteData(id) {
    let data = JSON.stringify({
      eventId: id,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/deleteevent`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getevents();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // ---- Get business API ---//
  function getevents() {
    gridRef?.current?.api?.showLoadingOverlay();

    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getevents`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setRowData(response.data.eventData);
        gridRef?.current?.api?.hideOverlay();
      })
      .catch(function (error) {
        // console.log(error);
        gridRef?.current?.api?.hideOverlay();
      });
  }

  const StatusRenderer = (props) => {
    let status = props?.data?.status ? "deactivate" : "activate";
    let message = "Are you sure you want to " + status + " this event?";
    return (
      <Modal_Popup
        status={props?.data?.status}
        onClick={() => {
          changeEventStatus(props?.data?._id);
        }}
        message={message}
      />
    );
  };

  const TrendingRender = (props) => {
    return (
      <ToggleButton
        handleToggle={() => {
          changeEventTrendingStatus(props?.data?._id);
        }}
        status={props?.data?.trending}
      />
    );
  };

  function changeEventStatus(eventId) {
    let data = JSON.stringify({
      eventId: eventId,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changeeventStatus`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getevents();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function changeEventTrendingStatus(eventId) {
    let data = JSON.stringify({
      eventId: eventId,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changeeventtrendingstatus`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getevents();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const ChildMessageRenderer = (props) => {
    const localId = JSON.parse(sessionStorage.getItem("userData"))?._id;

    return (
      <div className="iconActionList">
        <div
          className="ViewIcon"
          onClick={(e) => {
            e.preventDefault();
            window.open(
              `${process.env.REACT_APP_REDIRECTION_URL_EVENTS}/${props.data._id}/${encoded}/${localId}`,
              "_blank",
              "noopener,noreferrer"
            );
          }}
        >
          <AiFillEye className="ActionIcon" />
        </div>
        <div
          className="editIcon"
          onClick={() => {
            history.push({
              pathname: "UpdateEvents",
              state: { details: props.data },
            });
          }}
        >
          <MdModeEditOutline className="ActionIcon" />
        </div>
        <div
          className="DeleteIcon"
          onClick={() => {
            setDeleteEventsId(props.data._id);
          }}
          data-bs-toggle="modal"
          data-bs-target="#BusinessDeleteId"
        >
          <AiFillDelete
            className="ActionIcon"
            onClick={() => {
              setDeleteEventsId(props.data._id);
            }}
            data-bs-toggle="modal"
            data-bs-target="#BusinessDeleteId"
          />
        </div>
      </div>
    );
  };

  const groupValueGetter = (params) => {
    return params.data?.groupId;
  };

  const titleValueGetter = (params) => {
    return params.data.title;
  };

  const priorityValueGetter = (params) => {
    return params.data.priority;
  };

  const eventtypeValueGetter = (params) => {
    return params.data.seteventtype;
  };

  const customLowerCaseComparator = (valueA, valueB) => {
    if (typeof valueA === "string") {
      return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    }

    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  };

  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Events
                <div className="float-end btns_head d-flex">
                  <button
                    className="btn btn-theme btn-sm"
                    onClick={() => {
                      history.push("/AddEvents");
                    }}
                  >
                    Add New Event
                  </button>
                  {isdownloadUtils("uploadEvents") && (
                    <button
                      className="btn btn-theme btn-sm"
                      onClick={() => {
                        setUploadIsOpen(true);
                      }}
                    >
                      Upload Events
                    </button>
                  )}
                  {isdownloadUtils("downloadEvents") && (
                    <JsonDownload
                      title="Download Events"
                      fileName="events-excel"
                      downloadfile={rowData}
                      btnClassName={"btn btn-theme btn-sm"}
                      btnColor={"#f9560f"}
                    />
                  )}
                </div>
              </h4>
              <div>
                <Modal show={uploadIsOpenStatusModal} onHide>
                  <Modal.Body>
                    <div
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              <p>
                                <strong
                                  style={{
                                    color: uplaodStatus.status
                                      ? "green"
                                      : "red",
                                  }}
                                >
                                  {uplaodStatus.value}
                                </strong>
                              </p>
                              {/* <p> Status:<strong style={{ 'color': uplaodStatus.status ? 'green' : 'red' }}>{uplaodStatus.status}</strong></p> */}
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => {
                                fun();
                              }}
                              // onClick={resetForm}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              {email_alreay_exist.length > 0 && (
                                <div className="col-md-12 mb-4">
                                  <label className="form-label">
                                    Email Already Exists
                                  </label>
                                  <ul>
                                    {email_alreay_exist?.map((item) => {
                                      return <li>{item}</li>;
                                    })}
                                  </ul>
                                </div>
                              )}
                              {NotMatchedGroupdata.length > 0 && (
                                <div className="col-md-12 mb-4">
                                  <label className="form-label">
                                    Not Matched Groupdata
                                  </label>
                                  <ul>
                                    {NotMatchedGroupdata?.map((item) => {
                                      return <li>{item}</li>;
                                    })}
                                  </ul>
                                </div>
                              )}
                              {NotMatchedTagdata.length > 0 && (
                                <div className="col-md-12 mb-4">
                                  <label className="form-label">
                                    Not Matched Tagdata
                                  </label>
                                  <ul>
                                    {NotMatchedTagdata?.map((item) => {
                                      return <li>{item}</li>;
                                    })}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              <div>
                <Modal show={uploadIsOpen} onHide>
                  <Modal.Body>
                    <div
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <form onSubmit={handlesubmitUpload(uploadBusinessFiles)}>
                        <div className="modal-dialog modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Upload File
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                  fun();
                                }}
                              ></button>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12 mb-4">
                                  <label className="form-label">
                                    {" "}
                                    Upload File
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      className="form-control"
                                      type="file"
                                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                      {...uploadRegister("uploadFile", {
                                        required: true,
                                      })}
                                      onChange={(e) => {
                                        unregister("uploadFile");
                                      }}
                                    />
                                    {uploaderrors?.uploadFile?.type ===
                                      "required" && (
                                      <p className="error">
                                        This field is required
                                      </p>
                                    )}
                                    <div
                                      className="hint_box"
                                      style={{ display: "block" }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {loader && <LoadingSpinner />}
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn CancelBtn"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  somefun();
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="btn submitBtn"
                                disabled={loader}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
              <div
                className="modal fade DeletePopup"
                id="BusinessDeleteId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="">
                            {" "}
                            <p>Are you sure you want to delete this event?</p>
                            <button
                              type="button"
                              className="btn btn-danger CancelBtn"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              onClick={() => {
                                eventsdeleteData(DeleteEventsId);
                              }}
                              className="btn submitBtn"
                              data-bs-dismiss="modal"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ width: "100%" }}
                className="ag-theme-alpine tableFix"
              >
                <AgGridReact
                  rowHeight={rowHeight}
                  defaultColDef={defaultColDef}
                  rowData={rowData}
                  onGridReady={getevents}
                  overlayLoadingTemplate={
                    '<span class="ag-overlay-loading-center" style="margin: auto">Please wait while your rows are loading</span>'
                  }
                  ref={gridRef}
                  overlayNoRowsTemplate={
                    "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                  }
                  frameworkComponents={{
                    childMessageRenderer: ChildMessageRenderer,
                    srNoRenderer: SrNoRenderer,
                    titleRenderer: TitleRenderer,
                    imageRenderer: ImageRenderer,
                    dateRenderer: DateRenderer,
                    endDateRenderer: EndDateRenderer,
                    statusRenderer: StatusRenderer,
                    GroupRender: GroupRender,
                    TagEventRenderer: TagEventRenderer,
                    TrendingRender: TrendingRender,
                    priorityrender: Priorityrender,
                    eventTyperender: EventTyperender,
                  }}
                >
                  <AgGridColumn
                    width={90}
                    field="SrNo"
                    cellRenderer="srNoRenderer"
                    sortable={false}
                    filter={false}
                    pinned="left"
                  ></AgGridColumn>
                  <AgGridColumn
                    width={120}
                    field="Actions"
                    cellRenderer="childMessageRenderer"
                    colId="params"
                    sortable={false}
                    filter={false}
                    pinned="left"
                  ></AgGridColumn>

                  <AgGridColumn
                    width={200}
                    field="title"
                    sortable={true}
                    // comparator={customLowerCaseComparator}
                    // floatingFilter={true}
                    valueGetter={titleValueGetter}
                    filter={true}
                    pinned="left"
                  ></AgGridColumn>
                  <AgGridColumn
                    width={250}
                    field="groups"
                    cellRenderer="GroupRender"
                    sortable={true}
                    valueGetter={groupValueGetter}
                    filter={true}
                    // comparator={customLowerCaseComparator}
                  ></AgGridColumn>

                  <AgGridColumn
                    width={250}
                    field="tags"
                    cellRenderer="TagEventRenderer"
                    sortable={true}
                    // valueGetter={tagValueGetter}
                    filter={true}
                    comparator={customLowerCaseComparator}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={130}
                    field="Date"
                    cellRenderer="dateRenderer"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    // floatingFilter={true}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={140}
                    field="startTime"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    // floatingFilter={true}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={90}
                    field="endTime"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    // floatingFilter={true}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={130}
                    field="endDate"
                    cellRenderer="endDateRenderer"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    // floatingFilter={true}
                    filter={true}
                  ></AgGridColumn>

                  <AgGridColumn
                    cellRenderer="statusRenderer"
                    width={90}
                    field="status"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    cellRenderer="TrendingRender"
                    width={95}
                    field="trending"
                    sortable={true}
                    comparator={customLowerCaseComparator}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    cellRenderer="priorityrender"
                    width={95}
                    field="Priority"
                    sortable={true}
                    filter={true}
                    valueGetter={priorityValueGetter}
                  ></AgGridColumn>
                  <AgGridColumn
                    cellRenderer="eventTyperender"
                    width={95}
                    field="EventType"
                    sortable={true}
                    filter={true}
                    valueGetter={eventtypeValueGetter}
                    // comparator={customLowerCaseComparator}
                  ></AgGridColumn>

                  <AgGridColumn
                    width={90}
                    field="image"
                    cellRenderer="imageRenderer"
                    filter={false}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Events;
