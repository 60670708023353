import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import "./UpdateEvents.css";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import RenderImages from "../../components/RenderImages";
import Geocode from "react-geocode";
import { useForm } from "react-hook-form";
import Compressor from "compressorjs";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyCs3aM7dZ9UsKdxW-0K5iQUDWKz4Fyi-Cg");
Geocode.setLanguage("en");
Geocode.setRegion("es");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();

function UpdateEvents() {
  const defaultFonts = [
    "Arial",
    "Comic Sans MS",
    "Courier New",
    "Impact",
    "Georgia",
    "Tahoma",
    "Trebuchet MS",
    "Verdana",
  ];
  const sortedFontOptions = [
    "Logical",
    "Salesforce Sans",
    "Garamond",
    "Sans-Serif",
    "Serif",
    "Times New Roman",
    "Helvetica",
    ...defaultFonts,
  ].sort();

  const [content, setContent] = useState("");
  const config = {
    readonly: false,
    height: 400,
  };
  let location = useLocation();
  const [groupdata, setGroupdata] = useState([]);
  const [selectedMultipleGroups, setSelectedMultipleGroups] = useState(
    location.state.details.groups_Id.map((item, index) => {
      return { value: item.id, label: item.grpName };
    })
  );
  const [selectedOptiongroup, setselectedOptiongroup] = useState(
    location.state.details.groups_Id.map((item) => item)
  );
  const [selectedOptionGroupLabel, setSelectedOptionGroupLabel] = useState(
    location.state.details?.groupId.map((item, index) => {
      return { value: index, label: item };
    })
  );
  const [title, settitle] = useState("");
  const [Date, setDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [Price, setPrice] = useState();
  const [free, setfree] = useState(false);
  const [eventtype, setEventtype] = useState("");
  const [price, setprice] = useState("");
  const [startTime, setstartTime] = useState("");
  const [endTime, setendTime] = useState("");
  const [postalCode, setpostalCode] = useState(
    location.state.details.postalCode
  );
  const [address1, setaddress1] = useState(location.state.details.address1);
  const [address2, setaddress2] = useState("");
  const [country, setcountry] = useState("Singapore");
  const [contactName, setcontactName] = useState("");
  const [contactEmail, setcontactEmail] = useState("");
  const [contactPhone, setcontactPhone] = useState("");
  const [options, setoptions] = useState([]);
  const [selectedOptionone, setselectedOptionone] = useState([]);
  const [selectedOptionTagLabel, setSelectedOptionTagLabel] = useState(
    location.state.details?.tags.map((item, index) => {
      return { value: index, label: item };
    })
  );
  const [selectedOptionTagLabelTagIds, setSelectedOptionTagLabelTagIds] =
    useState(
      location.state.details?.tags_Id?.map((item, index) => {
        return { value: item?.id, label: item?.tagName };
      })
    );
  const [eventImages, setEventImages] = useState([]);
  const [smallDescription, setSmallDescription] = useState("");
  const [defaultImages, setDefaultImages] = useState([]);
  const [priority, setPriority] = useState("");
  const [group, setGroup] = useState([]); //----- THIS STATE IS USED FOR "GROUP" PARAMETER FOR SECTION API--//
  const [groupsName, setGroupsName] = useState([]); // THIS STATE IS USED FOR "GROUPSNAME" PARAMETER FOR SECTION API
  const [upload, setUpload] = useState({
    pictures: [],
    maxFileSize: 5242880,
    imgExtension: [".jpg", ".png", "jpeg"],
    defaultImages: [...location.state.details.image],
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
  } = useForm({ mode: "onBlur" });
  const [Online, setOnline] = useState(false);
  const [Inperson, setInperson] = useState(false);

  let history = useHistory();

  useEffect(() => {
    getnewgroup();
    gettag();
  }, []);

  useEffect(() => {
    initialArray();
  }, []);

  //--------FUNCTION FOR DEFAULT IMAGES--------//

  const [priceType, setpriceType] = useState("");
  function pricefield(events) {
    if (events === "Price") {
      location.state.details.price = "Price";
    } else {
      location.state.details.price = "free";
    }
    setpriceType(events);
  }

  const [eventType, setEventType] = useState(
    location.state.details.seteventtype
  );
  const checkHandler = (events) => {
    if (events === "Online") {
      location.state.details.seteventtype = "Online";
    } else {
      location.state.details.seteventtype = "Inperson";
    }

    setEventType(events);
  };

  function initialArray() {
    setDefaultImages(location.state.details.image);
  }

  //------------React-image-upload --------//

  let handleChange = (pictureFiles, files, uploadFiles) => {
    let defaultArray = [];
    uploadFiles?.map((item) => {
      if (item?.includes("https://")) {
        defaultArray.push(item);
      }
    });
    setDefaultImages(defaultArray);
    for (let i = 0; i < pictureFiles.length; i++) {
      let file = pictureFiles[i];
      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          // let display = URL.createObjectURL(compressedResult);
          // setAuthorImage(display);
          // setSendAuthorImage(compressedResult);
          setEventImages((prev) => [...prev, compressedResult]);
        },
      });
    }
    const { pictures } = upload;

    setUpload({
      ...upload,
      pictures: [...pictures, ...files],
    });
  };

  //-----------GET LATITUDE AND LONGITUDE OF SPECIFIC EVENT LOCATION---------//
  let latitude, longitude;

  const getlatlong = () => {
    let address = address1 === "" ? location.state.details.address1 : address1;
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        latitude = lat;
        longitude = lng;
      },
      (error) => {
        console.error(error);
      }
    );
  };
  getlatlong();

  //-----------LATITUDE AND LONGITUDE FUNCTION ENDS HERE---------//

  //-----------EVENT EDIT API---------//

  function EditEvents(postdata) {
    // --- THIS CONDITION IS USED FOR "GROUPSNAME" PARAMETER FOR SECTION API
    if (selectedOptionTagLabel.length === 0) {
      location.state.details.tags.map((item) => {
        groupsName.push(item);
      });
    } else {
      selectedOptionTagLabel.map((item) => {
        groupsName.push(item.label);
      });
    }
    if (selectedOptionGroupLabel.length === 0) {
      location.state.details.groupId.map((item) => {
        groupsName.push(item);
      });
    } else {
      selectedOptionGroupLabel.map((item) => {
        groupsName.push(item.label);
      });
    }

    // --- THIS CONDITION IS USED FOR "GROUP" PARAMETER FOR SECTION API

    if (selectedOptionone.length === 0) {
      location.state.details.tags.map((item) => {
        group.push(item);
      });
    } else {
      selectedOptionone.map((item) => {
        group.push(item);
      });
    }

    if (selectedOptiongroup.length === 0) {
      location.state.details.groupId.map((item) => {
        group.push(item);
      });
    } else {
      selectedOptiongroup.map((item) => {
        group.push(item);
      });
    }
    let newimage = [];
    location.state.details.image.forEach((image) => {
      if (image !== null) {
        newimage.push(image);
      }
    });

    let data = new FormData();
    data.append("eventId", location.state.details._id);
    data.append(
      "priority",
      priority
        ? priority
        : location.state.details.priority
        ? location.state.details.priority
        : 0
    );

    data.append("group", group);
    data.append("groupsName", groupsName);
    data.append(
      "groups_Id",
      JSON.stringify(
        selectedMultipleGroups.map((item) => {
          if (item.value) {
            return { id: item.value, grpName: item.label };
          }
          return item;
        })
      )
    );

    data.append(
      "groupId",
      selectedOptionGroupLabel.map((item) => item.label).toString()
    );

    data.append("title", title === "" ? location.state.details.title : title);

    eventImages?.map((item) => {
      data.append("image", item === null ? newimage : item);
    });

    defaultImages?.map((item) => {
      data.append("image", defaultImages === null || "" ? newimage : item);
    });

    data.append(
      "address1",
      address1 === "" ? location.state.details.address1 : address1
    );
    data.append(
      "address2",
      address2 === " "
        ? location.state.details.postdata.address2
        : postdata.address2
    );
    data.append(
      "postalCode",
      postdata.postalCode === ""
        ? location.state.details.postdata.postalCode
        : postdata.postalCode
    );

    data.append(
      "country",
      country === "" ? location.state.details.country : country
    );
    data.append(
      "startTime",
      startTime === "" ? location.state.details.startTime : startTime
    );
    data.append(
      "endTime",
      endTime === "" ? location.state.details.endTime : endTime
    );
    data.append("Date", Date === "" ? location.state.details.Date : Date);
    data.append(
      "endDate",
      endDate === "" ? location.state.details.endDate : endDate
    );
    data.append(
      "price",
      priceType !== "free"
        ? price == ""
          ? location.state.details.price
          : price
        : priceType
    );
    data.append(
      "seteventtype",
      eventType == "" ? location.state.details.seteventtype : eventType
    );
    data.append(
      "smallDescription",
      smallDescription === "" || null
        ? location.state.details.smallDescription
        : smallDescription
    );
    if (eventType == "Inperson") {
      data.append("lat", latitude);
      data.append("long", longitude);
    } else {
      data.append("lat", 0);
      data.append("long", 0);
    }
    data.append(
      "description",
      content === "" ? location.state.details.description : content
    );
    data.append(
      "contactName",
      contactName === "" ? location.state.details.contactName : contactName
    );
    data.append(
      "contactEmail",
      contactEmail === "" ? location.state.details.contactEmail : contactEmail
    );
    data.append(
      "contactPhone",
      contactPhone === ""
        ? location.state.details.contactPhone.includes("+")
          ? location.state.details.contactPhone
          : "+" + location.state.details.contactPhone
        : "+65" + contactPhone
    );
    data.append(
      "tags",
      selectedOptionTagLabel.map((item) => item.label).toString()
    );

    data.append(
      "tags_Id",
      JSON.stringify(
        selectedOptionTagLabelTagIds.map((item) => {
          if ("id" in item) {
            return { id: item.id, tagName: item.tagName };
          }
          return { id: item.value, tagName: item.label };
        })
      )
    );
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/editevent`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        history.push("./Events");
        reset();
      })

      .catch(function (error) {
        // console.log(error);
      });
  }

  function getnewgroup() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getgroups`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        let groupData = [];
        response.data.data?.map((grup, index) => {
          groupData.push({ label: grup.groups, value: grup._id });
        });
        setGroupdata(groupData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  const handlechangegroupmulti = (event) => {
    let array = [];
    let multigroupArray = [];
    let arrayLabel = [];
    event.forEach((option) => {
      array.push(option.value);
      multigroupArray.push({ id: option.value, grpName: option.label });
    });
    event.forEach((option) => {
      arrayLabel.push(option.label);
    });
    setSelectedMultipleGroups(multigroupArray);
    setselectedOptiongroup(array);
    setSelectedOptionGroupLabel(event);
  };

  // ---- get Postal Code API ---//
  function getAddressPostcode(postalCode) {
    if (postalCode?.length === 6) {
      let data = JSON.stringify({
        postalCode,
      });

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_BASEURL}/getAddressPostcode`,
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          const address =
            response.data.addressfrompostcode?.address[0].ADDRESS.slice(
              0,
              response.data.addressfrompostcode?.address[0].ADDRESS.lastIndexOf(
                "SINGAPORE"
              )
            );
          setaddress1(address);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }

  // ---- On Enter key Calling API ---//
  const postalCodeChangeHandle = (e) => {
    if (e.target?.value?.length === 6) {
      getAddressPostcode(e.target.value);
    }
  };

  // ---- get tags API ---//
  const handlechangetag = (event) => {
    event.persist();
    let array = [];
    let arrayLabel = [];
    let arrayLabelids = [];
    event.forEach((value) => {
      array.push(value.value);
    });
    event.forEach((option) => {
      arrayLabel.push(option.label);
    });
    event.forEach((option) => {
      arrayLabelids.push({ id: option.value, tagName: option.label });
    });
    setselectedOptionone(array);
    setSelectedOptionTagLabel(event);
    setSelectedOptionTagLabelTagIds(arrayLabelids);
  };

  function gettag() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/gettag`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        let tagData = [];
        response.data.tagData.forEach((tag, index) => {
          tagData.push({ value: tag._id, label: tag.tags });
        });
        setoptions(tagData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  let imagelist = location.state.details.image;
  const handleRemove = (index) => {
    let displayArray = [];
    imagelist.splice(index, 1);
    displayArray = imagelist;
    renderImages(displayArray);
  };

  const renderImages = (images) => {
    return images?.map((single_image, index) => {
      return (
        <>
          <span className="mainButton">
            <IoIosClose
              className="crossButton"
              onClick={() => handleRemove(index)}
            />
            <img
              style={{ width: "110px", height: "140px" }}
              src={single_image}
              key={single_image}
            />
          </span>
        </>
      );
    });
  };

  const optionspriority = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];

  function selectPriorityValue(event) {
    setPriority(event.value);
  }

  return (
    <>
      <Header />
      <Sidebar />
      <form onSubmit={handleSubmit(EditEvents)}>
        <div className="page-wrapper">
          <div className="container-fluid min_height">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Edit Event</h4>
                <div className="branchData">
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label className="form-label">Event Images</label>
                      <div className="App">
                        <RenderImages {...upload} handleChange={handleChange} />
                      </div>
                    </div>

                    <div className="col-md-6 mb-3">
                      <label className="form-label">Select Group</label>
                      <Select
                        isMulti
                        defaultValue={selectedMultipleGroups}
                        key={location.state.details?.groups_Id?.map((item) => {
                          return item.id;
                        })}
                        name="group"
                        options={groupdata}
                        onChange={(e) => {
                          handlechangegroupmulti(e);
                        }}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Event Title</label>
                      <input
                        defaultValue={location.state.details.title}
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          settitle(e.target.value);
                        }}
                      />{" "}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Event Start Date</label>
                      <input
                        defaultValue={location.state.details.Date}
                        type="date"
                        className="form-control"
                        onChange={(e) => {
                          setDate(e.target.value);
                        }}
                      />{" "}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Event End Date</label>
                      <input
                        defaultValue={location.state.details.endDate}
                        type="date"
                        className="form-control"
                        onChange={(e) => {
                          setendDate(e.target.value);
                        }}
                      />{" "}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Event Price</label>
                      <div className="d-flex justify-content-center">
                        <label className="container">
                          Free
                          <input
                            type="radio"
                            name="price"
                            value="free"
                            defaultChecked={
                              location.state.details.price == "free"
                                ? true
                                : false
                            }
                            onClick={() => {
                              pricefield("free");
                              setfree(true);
                              setPrice(false);
                            }}
                          />
                        </label>
                        <label className="container">
                          Price
                          <input
                            type="radio"
                            name="price"
                            value="Price"
                            defaultChecked={
                              location.state.details.price &&
                              location.state.details.price !== "free"
                                ? true
                                : false
                            }
                            onClick={() => {
                              pricefield("Price");
                              setfree(false);
                              setPrice(true);
                            }}
                          />
                        </label>
                      </div>
                      {Price == true ||
                      (location.state.details.price &&
                        location.state.details.price !== "free") ? (
                        <div className="col-md-12 mb-3">
                          <input
                            defaultValue={location.state.details.price}
                            type="number"
                            className="form-control"
                            placeholder="$"
                            onChange={(e) => {
                              setprice(e.target.value);
                            }}
                          />{" "}
                        </div>
                      ) : null}
                    </div>

                    <div className="col-md-6 mb-3">
                      <label className="form-label">Event Start Time</label>
                      <input
                        defaultValue={location.state.details.startTime}
                        type="time"
                        className="form-control"
                        onChange={(e) => {
                          setstartTime(e.target.value);
                        }}
                      />{" "}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Event End Time</label>
                      <input
                        defaultValue={location.state.details.endTime}
                        type="time"
                        className="form-control"
                        onChange={(e) => {
                          setendTime(e.target.value);
                        }}
                      />{" "}
                    </div>

                    <div className="col-md-12 mt-3">
                      <label className="form-label">
                        Brief Event Description
                      </label>
                      <textarea
                        type="textarea"
                        defaultValue={location.state.details.smallDescription}
                        className="form-control"
                        onChange={(e) => {
                          setSmallDescription(e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-md-12 mb-3">
                      <label className="form-label">Event Description</label>
                      <div className="App">
                        <SunEditor
                          width="100%"
                          plugin=""
                          height="100%"
                          defaultValue={location.state.details.description.toString()}
                          onChange={(event) => setContent(event)}
                          setOptions={{
                            buttonList: [
                              ["undo", "redo"],
                              ["font", "fontSize", "formatBlock"],
                              ["paragraphStyle", "blockquote"],
                              [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript",
                              ],
                              ["fontColor", "hiliteColor"],
                              ["align", "list", "lineHeight"],
                              ["outdent", "indent"],
                              ["fullScreen", "showBlocks", "codeView"],
                              ["preview", "print"],
                              ["removeFormat"],

                              [
                                ":i-More Misc-default.more_vertical",
                                "image",
                                "video",
                                "audio",
                                "link",
                                "fullScreen",
                                "showBlocks",
                                "codeView",
                                "preview",
                                "print",
                                "save",
                                "template",
                              ],
                              [
                                ":r-More Rich-default.more_plus",
                                "table",
                                "horizontalRule",
                                "imageGallery",
                              ],
                            ],
                            defaultTag: "div",
                            minHeight: "300px",
                            showPathLabel: false,
                            font: sortedFontOptions,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3 ">
                      <div className="">
                        <label className="form-label">
                          Select Event Type :
                        </label>
                        <input
                          name="eventtype"
                          type="radio"
                          value="Online"
                          defaultValue={location.state.details.seteventtype}
                          defaultChecked={
                            location.state.details.seteventtype == "Online"
                              ? true
                              : false
                          }
                          onClick={() => {
                            checkHandler("Online");
                            setOnline(true);
                            setInperson(false);
                          }}
                          style={{ marginLeft: "10px", marginRight: "3px" }}
                        />
                        <label for="On-line">On-line</label>

                        <input
                          name="eventtype"
                          type="radio"
                          value="Inperson"
                          defaultValue={location.state.details.seteventtype}
                          defaultChecked={
                            location.state.details.seteventtype !== "Online"
                              ? true
                              : false
                          }
                          onClick={() => {
                            checkHandler("Inperson");
                            setOnline(false);
                            setInperson(true);
                          }}
                          style={{ marginLeft: "40px", marginRight: "5px" }}
                        />
                        <label for="In-person">In-person</label>
                      </div>
                      {Inperson === true ||
                      location.state.details.seteventtype === "Inperson" ? (
                        <div className="col-md-12 mb-3 d-flex justify-content-around">
                          <div
                            className="col-md-2 mb-3"
                            style={{ marginRight: "10px" }}
                          >
                            <label className="form-label">Postal Code</label>
                            <input
                              defaultValue={location.state.details.postalCode}
                              type="tel"
                              name="postalCode"
                              onKeyUp={postalCodeChangeHandle}
                              className="form-control"
                              {...register("postalCode", {
                                required: true,
                                maxLength: 6,
                                minLength: 6,
                              })}
                            />
                            {errors?.postalCode?.type === "required" && (
                              <p className="error">This field is required</p>
                            )}
                            {errors?.postalCode?.type === "maxLength" && (
                              <p className="error">Only 6 digit number</p>
                            )}
                            {errors?.postalCode?.type === "minLength" && (
                              <p className="error">Only 6 digit number</p>
                            )}
                          </div>
                          <div
                            className="col-md-4 mb-3"
                            style={{ marginRight: "10px" }}
                          >
                            <label className="form-label">Address 1</label>
                            <input
                              onChange={(e) => {
                                setaddress1(e.target.value);
                              }}
                              value={address1}
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div
                            className="col-md-4 mb-3"
                            style={{ marginRight: "10px" }}
                          >
                            <label className="form-label">Address 2</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={location.state.details.address2}
                              {...register("address2")}
                            />
                          </div>
                          <div
                            className="col-md-2 mb-3"
                            style={{ marginRight: "10px" }}
                          >
                            <label className="form-label">Country</label>
                            <input
                              value={country}
                              defaultValue={location.state.details.country}
                              type="text"
                              className="form-control"
                              {...register("country")}
                            />{" "}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="form-label">Contact Name</label>
                      <input
                        defaultValue={location.state.details.contactName}
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          setcontactName(e.target.value);
                        }}
                      />{" "}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="form-label">Contact Email</label>
                      <input
                        defaultValue={location.state.details.contactEmail}
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          setcontactEmail(e.target.value);
                        }}
                      />{" "}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="form-label">Contact Phone</label>
                      <input
                        defaultValue={location.state.details.contactPhone?.slice(
                          3
                        )}
                        type="number"
                        className="form-control"
                        onChange={(e) => {
                          setcontactPhone(e.target.value);
                        }}
                      />{" "}
                    </div>

                    <div className="col-md-6 mb-3">
                      <label className="form-label">Tags</label>
                      <Select
                        isMulti
                        defaultValue={selectedOptionTagLabelTagIds}
                        key={location.state.details?.tags_Id?.map(
                          (item, index) => {
                            return index;
                          }
                        )}
                        options={options}
                        name="tags"
                        onChange={(e) => {
                          e.persist = () => {};
                          handlechangetag(e);
                        }}
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <label className="form-label">Select Priority</label>
                      <Select
                        defaultValue={optionspriority.map((item) => {
                          if (
                            location.state.details?.priority?.toString() ===
                            item.label
                          ) {
                            return item;
                          }
                        })}
                        key={optionspriority.map((item) => {
                          if (location.state.details.priority === item.value) {
                            return item.value;
                          }
                        })}
                        options={optionspriority}
                        name="Priority"
                        onChange={(event) => selectPriorityValue(event)}
                      />
                    </div>
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn CancelBtn me-3"
                        onClick={() => {
                          history.push("./Events");
                        }}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn submitBtn me-3 ">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Footer />
    </>
  );
}

export default UpdateEvents;
