import React from "react";

function Input_Renderer({ label, labelClassName, register, type, className, name, pattern, error, defaultValue }) {
    return (
        <>
            <label className={labelClassName}> {label} </label>
            <input
                autoComplete="new-password"
                type={type}
                {...register(name, {
                    pattern: pattern
                })}
                className={className}
                defaultValue={defaultValue}
            />
            {error?.type == "pattern" && (<p>Alphabetical characters only</p>)}

        </>
    )
}

export default React.memo(Input_Renderer);