import { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import Select from "react-select";

const UpdateSections = () => {
  const history = useHistory();
  const [locationKeys, setLocationKeys] = useState([]);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          history.replace({
            pathname: "/Sections",
            state: null,
          });
          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          history.replace({
            pathname: "/Sections",
            state: null,
          });
          // Handle back event
        }
      }
    });
  }, [locationKeys]);

  useEffect(() => {
    if (editSectionData.Objectname === "Classes") {
      isDisabledonLogicandGroupingClass();
    } else {
      isDisabledonLogicandGrouping();
    }
  }, []);

  const location = useLocation();
  let editSectionData = location.state.details.updatedData;
  let logicType = location.state.details?.logicType;
  const [groupingFilter, setGroupingFilter] = useState([]);
  const [groupingOptions, setGroupingOptions] = useState([]);
  const [objectTypeValue, setObjectTypeValue] = useState([]);
  const [logic, setLogic] = useState(editSectionData.logictype);
  const [description, setDescription] = useState(editSectionData.description);
  const [trendingSend, setTrendingSend] = useState("");
  const [statusSend, setStatusSend] = useState("");
  const [objectName, setObjectName] = useState("");
  const [groupingSend, setGroupingSend] = useState("");
  const [groupingFilterSend, setGroupingFilterSend] = useState(
    editSectionData?.filtertype?._id
  );
  const [show, setShow] = useState("");
  const [unHide, setUnHide] = useState(true);
  const [row, setrow] = useState("");
  const [error, seterror] = useState("");
  const [category, setcategory] = useState([]);
  const [subcategory, setsubcategory] = useState([]);
  const [group, setgroup] = useState([]);
  const [tag, settag] = useState([]);
  const [productc, setproductc] = useState([]);
  const [tagsgroup, settagsgroup] = useState([]);
  const [pagination, setpagination] = useState("");

  useEffect(() => {
    if (logicType == "Admin") {
      setShow(true);
      setUnHide(true);
    } else if (logicType == "Related") {
      setShow(false);
      setUnHide(true);
    } else if (logicType == "ATOZ") {
      setShow(false);
      setUnHide(false);
    }
  }, [logicType]);

  const resetform = () => {
    setLogic("");
    setDescription("");
    setTrendingSend("");
    setGroupingSend("");
    setObjectName("");
    setGroupingFilterSend("");
    setStatusSend("");
    setShow(false);
    setUnHide(false);
    setGroupingFilter([]);
    setGroupingOptions([]);
    setObjectTypeValue([]);
    setrow("");
    setpagination("");
  };

  function fun() {
    resetform();
    window.location.reload(true);
  }

  useEffect(() => {
    if (editSectionData?.group === "tags") {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_BASEURL}/gettag`,
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          let optionArray = [];
          response.data?.tagData?.map((item) => {
            optionArray.push({ value: item._id, label: item.tags });
          });
          setGroupingFilter(optionArray);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }

    if (editSectionData?.group === "taggroups") {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_BASEURL}/getgrouptags`,
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          let optionArray = [];
          response?.data?.taggroupData?.map((item) => {
            optionArray.push({ value: item._id, label: item.groupName });
          });
          setGroupingFilter(optionArray);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }

    if (editSectionData?.group === "groups") {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_BASEURL}/getgroups`,
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then(function (response) {
          let optionArray = [];
          response.data?.data?.map((item) => {
            optionArray.push({ value: item._id, label: item.groups });
          });
          setGroupingFilter(optionArray);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }

    if (editSectionData?.group === "subCategory") {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_BASEURL}/getSubcategory`,
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(function (response) {
          let optionArray = [];
          response.data.SubcategoryData.map((item) => {
            optionArray.push({ value: item._id, label: item.subCategory });
          });
          setGroupingFilter(optionArray);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }

    if (editSectionData?.group === "category") {
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_BASEURL}/getCategory`,
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(function (response) {
          let optionArray = [];
          response.data?.CategoryData?.map((item) => {
            optionArray.push({ value: item._id, label: item.category });
          });
          setGroupingFilter(optionArray);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }, [editSectionData.group]);

  let [logics, setLogics] = useState([
    {
      value: "0",
      label: "Admin",
      [editSectionData.page !== "Homepage"
        ? editSectionData.page !== "Group" && "isDisabled"
        : ""]: true,
    },
    {
      value: "1",
      label: "Related",
      [editSectionData.page === "Homepage"
        ? "isDisabled"
        : editSectionData.page === "Group"
        ? "isDisabled"
        : ""]: true,
    },
    { value: "2", label: "ATOZ" },
  ]);

  let objectTypes = [
    { value: "0", label: "Classes" },
    { value: "1", label: "Group" },
    { value: "2", label: "Event" },
    { value: "3", label: "Articles" },
  ];

  let grouping = [
    { value: "0", label: "Subject Group" },
    { value: "1", label: "Subject" },
    { value: "2", label: "Tag-Group" },
    { value: "3", label: "tags" },
    { value: "4", label: "Group" },
  ];

  let trending = [
    { value: "0", label: "true" },
    { value: "1", label: "false" },
  ];

  let status = [
    { value: "0", label: "true" },
    { value: "1", label: "false" },
  ];

  //--------------Default Value States------------//
  let defaultLogics = [];

  logics.map((item) => {
    if (item.label === editSectionData?.logictype) {
      defaultLogics.push({ value: item.value, label: item.label });
    }
  });

  let defaultObjectTypes = [];

  objectTypes.map((item) => {
    if (item.label === editSectionData?.Objectname) {
      defaultObjectTypes.push({ value: item.value, label: item.label });
    }
  });

  let [defaultGrouping, setDefaultGrouping] = useState([
    {
      value: "0",
      label: "Subject Group",
      [editSectionData.logictype == "Related" ? "isDisabled" : ""]: true,
    },
    {
      value: "1",
      label: "Subject",
      [editSectionData.logictype == "Related" ? "isDisabled" : ""]: true,
    },
    {
      value: "2",
      label: "Tag-Group",
      [editSectionData.logictype == "Related" ? "isDisabled" : ""]: true,
    },
    { value: "3", label: "Tags" },
    { value: "4", label: "Group" },
    {
      value: "5",
      label: "Product-Category",
      [editSectionData.logictype == "Related" ? "isDisabled" : ""]: true,
    },
    {
      value: "6",
      label: "Product-Category Group",
      [editSectionData.logictype == "Related" ? "isDisabled" : ""]: true,
    },
  ]);

  let defaultTrending = [];
  trending.map((item) => {
    if (item.label === editSectionData?.trending?.toString()) {
      defaultTrending.push({ value: item.value, label: item.label });
    }
  });

  let defaultStatus = [];
  status.map((item) => {
    if (item.label === editSectionData?.status?.toString()) {
      defaultStatus.push({ value: item.value, label: item.label });
    }
  });

  // ---- Edit business Details API ---//
  function editSections() {
    if (logic === "Admin") {
      if (groupingFilterSend) {
        let data = JSON.stringify({
          sectionId: editSectionData._id,
          pageName: editSectionData.page,
          logictype: logic,
          description: description,
          trending: trendingSend ? trendingSend : editSectionData.trending,
          Objectname: objectName ? objectName : editSectionData.Objectname,
          group: groupingSend ? groupingSend : editSectionData?.group,
          filtertype: groupingFilterSend
            ? groupingFilterSend
            : editSectionData?.filtertype,
          sorting: "",
          status: statusSend ? statusSend : editSectionData.status,
          rows: row ? row : editSectionData.rows,
          pagination: pagination ? pagination : editSectionData.pagination,
        });

        let config = {
          method: "post",
          url: `${process.env.REACT_APP_BASEURL}/editsection`,
          headers: {
            Authorization: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            // location.state.details.getSection();
            history.replace({
              pathname: "/Sections",
              state: `${location.state.details.rowIndex + 1}. ${
                editSectionData.section
              } of ${editSectionData.page} is Successfully Updated`,
            });
            setTimeout(() => {
              resetform();
            }, 1000);
          })
          .catch(function (error) {
            // console.log(error);
          });
      } else {
        seterror("please select grouping-filter");
      }
    } else {
      let data = JSON.stringify({
        sectionId: editSectionData._id,
        pageName: editSectionData.page,
        logictype: logic,
        description: description,
        trending: trendingSend ? trendingSend : editSectionData.trending,
        Objectname: objectName ? objectName : editSectionData.Objectname,
        group: groupingSend ? groupingSend : editSectionData?.group,
        filtertype: groupingFilterSend
          ? groupingFilterSend
          : editSectionData?.filtertype,
        sorting: "",
        status: statusSend ? statusSend : editSectionData.status,
        rows: row ? row : editSectionData.rows,
        pagination: pagination ? pagination : editSectionData.pagination,
      });

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_BASEURL}/editsection`,
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          // location.state.details.getSection();
          history.replace({
            pathname: "/Sections",
            state: `${location.state.details.rowIndex + 1}. ${
              editSectionData.section
            } of ${editSectionData.page} is Successfully Updated`,
          });
          setTimeout(() => {
            resetform();
          }, 1000);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }

  const isDisabledonLogicandGroupingClass = () => {
    if (logic === "Admin") {
      setDefaultGrouping((prev) => {
        return prev.map((item) => {
          if (
            item.label == "Product-Category" ||
            item.label == "Product-Category Group"
          ) {
            return { value: item.value, label: item.label, isDisabled: true };
          } else {
            return { value: item.value, label: item.label };
          }
        });
      });
    }
  };

  const isDisabledonLogicandGrouping = () => {
    if (logic === "Admin") {
      setDefaultGrouping((prev) => {
        return prev.map((item) => {
          if (
            item.label == "Tags" ||
            item.label == "Group" ||
            item.label == "Tag-Group"
          ) {
            return { value: item.value, label: item.label };
          } else {
            return { value: item.value, label: item.label, isDisabled: true };
          }
        });
      });
    }
  };

  //----------------handle object type changes-----------//
  function handleObjectTypes(event) {
    if (event.label === "Classes") {
      grouping = [{ value: "2", label: "tags" }];
      setObjectTypeValue(event.label);
      setGroupingOptions(grouping);
      setObjectName(event.label);
      isDisabledonLogicandGroupingClass();
    } else if (event.label === "Event") {
      grouping = [{ value: "2", label: "tags" }];
      setObjectTypeValue(event);
      setGroupingOptions(grouping);
      setObjectName(event.label);
      isDisabledonLogicandGrouping();
    } else if (event.label === "Group") {
      grouping = [
        { value: "0", label: "Tag-Group" },
        { value: "1", label: "tags" },
      ];
      setObjectTypeValue(event);
      setGroupingOptions(grouping);
      setObjectName(event.label);
      isDisabledonLogicandGrouping();
    } else if (event.label === "Articles") {
      grouping = [
        { value: "0", label: "Group" },
        { value: "1", label: "Tag-Group" },
        { value: "2", label: "tags" },
      ];
      setObjectTypeValue(event);
      setGroupingOptions(grouping);
      setObjectName(event.label);
      isDisabledonLogicandGrouping();
    }
  }

  function handleLogicChange(event) {
    setLogic(event.label);
    if (event.label == "Admin") {
      setShow(true);
      setUnHide(true);
      setDefaultGrouping((prev) => {
        return prev.map((item) => {
          return { value: item.value, label: item.label };
        });
      });
    } else if (event.label == "Related") {
      setDefaultGrouping((prev) => {
        return prev.map((item) => {
          if (item.label == "Tags" || item.label == "Group") {
            return { value: item.value, label: item.label };
          } else {
            return { value: item.value, label: item.label, isDisabled: true };
          }
        });
      });

      setShow(false);
      setUnHide(true);
    } else if (event.label == "ATOZ") {
      setUnHide(false);
      setShow(false);
      setDefaultGrouping((prev) => {
        return prev.map((item) => {
          return { value: item.value, label: item.label };
        });
      });
    }
  }

  function handleDescriptionChange(event) {
    setDescription(event.target.value);
  }

  function handleTrendingChange(event) {
    setTrendingSend(event.label);
  }

  function handleStatusChange(event) {
    setStatusSend(event.label);
  }

  function handleGroupingFilterChange(event) {
    setGroupingFilterSend(event.value);
    seterror("");
  }

  function handleGroupingChange(event) {
    setGroupingFilterSend("");

    let setgroupingFilter = "";
    if (event.label === "Tags") {
      setGroupingFilter(tag);
      setgroupingFilter = "tags";
    } else if (event.label === "Tag-Group") {
      setGroupingFilter(tagsgroup);
      setgroupingFilter = "taggroups";
    } else if (event.label === "Product-Category") {
      setGroupingFilter(productc);
      setgroupingFilter = "productcategory";
    } else if (event.label === "Subject Group") {
      setGroupingFilter(category);
      setgroupingFilter = "category";
    } else if (event.label === "Subject") {
      setGroupingFilter(subcategory);
      setgroupingFilter = "subCategory";
    } else if (event.label === "Group") {
      setGroupingFilter(group);
      setgroupingFilter = "groups";
    }
    // else if(event.label === "Tag-Group"){}
    setGroupingSend(setgroupingFilter);
  }
  useEffect(() => {
    gettag();
    getcategorygroup();
    getcategory();
    gettaggroup();
    getgroup();
    getproductcategory();
  }, []);

  const gettag = () => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/gettag`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let optionArray = [];
        response.data?.tagData?.map((item) => {
          optionArray.push({ value: item._id, label: item.tags });
        });
        settag(optionArray);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const getcategory = () => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getCategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        let optionArray = [];
        response.data?.CategoryData?.map((item) => {
          optionArray.push({ value: item._id, label: item.category });
        });
        setcategory(optionArray);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const getcategorygroup = () => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getSubcategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        let optionArray = [];
        response.data.SubcategoryData.map((item) => {
          optionArray.push({ value: item._id, label: item.subCategory });
        });
        setsubcategory(optionArray);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const gettaggroup = () => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getgrouptags`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let optionArray = [];
        response?.data?.taggroupData?.map((item) => {
          optionArray.push({ value: item._id, label: item.groupName });
        });
        settagsgroup(optionArray);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const getgroup = () => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getgroups`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let optionArray = [];
        response.data?.data?.map((item) => {
          optionArray.push({ value: item._id, label: item.groups });
        });
        setgroup(optionArray);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const getproductcategory = () => {
    let optionArray = [];
    setproductc(optionArray);
  };

  return (
    <Fragment>
      <div
        style={{
          width: "37%",
          margin: "50px auto",
          "box-shadow": "1px 2px 3px 4px rgb(20 40 20 / 15%)",
          padding: "10px 20px",
        }}
      >
        <div className="row">
          <div className="col-md-12 mb-4 d-flex justify-content-center">
            <h2 style={{ color: "#f9560f" }}>Update Section</h2>
          </div>
          <div className="col-md-12 mb-4">
            <label className="form-label">Serial Number: </label>{" "}
            <span>{location.state.details.rowIndex + 1}</span>
          </div>
          <div className="col-md-12 mb-4">
            <label className="form-label">Page</label>
            <div className="position-relative">
              <input
                defaultValue={editSectionData.page}
                type="text"
                className="form-control"
                disabled
              />
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>
          <div className="col-md-12 mb-4">
            <label className="form-label"> Section </label>
            <div className="position-relative">
              <input
                value={editSectionData.section}
                type="text"
                className="form-control"
                disabled
              />
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>
          <div className="col-md-12 mb-4">
            <label className="form-label">Logics</label>
            <div className="position-relative">
              <Select
                defaultValue={defaultLogics}
                key={defaultLogics.map((item) => item.value)}
                options={logics}
                onChange={handleLogicChange}
              />
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>
          <div className="col-md-12 mb-4">
            <label className="form-label"> Description </label>
            <div className="position-relative">
              <input
                defaultValue={editSectionData?.description}
                key={editSectionData?.description}
                type="text"
                className="form-control"
                onChange={handleDescriptionChange}
              />
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>
          <div className="col-md-12 mb-4">
            <label className="form-label"> Object's Type </label>
            <div className="position-relative">
              <Select
                defaultValue={defaultObjectTypes}
                key={defaultObjectTypes.map((item) => item.value)}
                options={objectTypes}
                onChange={handleObjectTypes}
              />
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>
          <div
            className="col-md-12 mb-4"
            style={{ display: unHide ? "block" : "none" }}
          >
            <label className="form-label"> Grouping </label>
            <div className="position-relative">
              <Select
                defaultValue={defaultGrouping.map((item) => {
                  if (
                    editSectionData?.group === "tags" &&
                    item.label === "Tags"
                  ) {
                    return item;
                  } else if (
                    editSectionData?.group === "taggroups" &&
                    item.label === "Tag-Group"
                  ) {
                    return item;
                  } else if (
                    editSectionData?.group === "productcategory" &&
                    item.label === "Product-Category"
                  ) {
                    return item;
                  } else if (
                    editSectionData?.group === "category" &&
                    item.label === "Subject Group"
                  ) {
                    return item;
                  } else if (
                    editSectionData?.group === "subCategory" &&
                    item.label === "Subject"
                  ) {
                    return item;
                  } else if (
                    editSectionData?.group === "groups" &&
                    item.label === "Group"
                  ) {
                    return item;
                  }
                  // else if(editSectionData?.group === "tags" && item.label === "Product-Category Group"){
                  //     return item;
                  // }
                })}
                key={defaultGrouping.map((item) => {
                  if (
                    editSectionData?.group === "tags" &&
                    item.label === "Tags"
                  ) {
                    return item.value;
                  } else if (
                    editSectionData?.group === "taggroups" &&
                    item.label === "Tag-Group"
                  ) {
                    return item.value;
                  } else if (
                    editSectionData?.group === "productcategory" &&
                    item.label === "Product-Category"
                  ) {
                    return item.value;
                  } else if (
                    editSectionData?.group === "category" &&
                    item.label === "Subject Group"
                  ) {
                    return item.value;
                  } else if (
                    editSectionData?.group === "subCategory" &&
                    item.label === "Subject"
                  ) {
                    return item.value;
                  } else if (
                    editSectionData?.group === "groups" &&
                    item.label === "Group"
                  ) {
                    return item.value;
                  }
                  // else if(editSectionData?.group === "tags" && item.label === "Product-Category Group"){
                  //     return item.value;
                  // }
                })}
                options={defaultGrouping}
                onChange={handleGroupingChange}
              />
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>

          <div
            className="col-md-12 mb-4"
            style={{ display: show ? "block" : "none" }}
          >
            <label className="form-label">Grouping-Filter</label>
            <div className="position-relative">
              <Select
                defaultValue={groupingFilter.map((item) => {
                  if (item.value == editSectionData?.filtertype?._id) {
                    return { value: item.value, label: item.label };
                  }
                })}
                key={groupingFilter.map((item) => {
                  if (item.value === editSectionData?.filtertype) {
                    return item.value;
                  }
                })}
                options={groupingFilter}
                onChange={handleGroupingFilterChange}
              />
              {error && <p style={{ color: "red" }}> {error}</p>}
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>

          <div className="col-md-12 mb-4">
            <label className="form-label">Trending</label>
            <div className="position-relative">
              <Select
                defaultValue={defaultTrending}
                key={defaultTrending.map((item) => item.value)}
                options={trending}
                onChange={handleTrendingChange}
              />
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>

          <div className="col-md-12 mb-4">
            <label className="form-label">Status</label>
            <div className="position-relative">
              <Select
                defaultValue={defaultStatus}
                key={defaultStatus.map((item) => item.value)}
                options={status}
                onChange={handleStatusChange}
              />
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>

          <div className="col-md-12 mb-4">
            <label className="form-label">No. of Rows</label>
            <div className="position-relative">
              <input
                defaultValue={editSectionData?.rows}
                key={editSectionData?.rows}
                type="text"
                className="form-control"
                onChange={(e) => {
                  setrow(e.target.value);
                }}
              />
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>
          <div className="col-md-12 mb-4">
            <label className="form-label">Pagination</label>
            <div className="position-relative">
              <input
                value={editSectionData?.pagination}
                key={editSectionData?.pagination}
                type="text"
                className="form-control"
                onChange={(e) => {
                  setpagination(e.target.value);
                }}
              />
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>

          <div className="col-md-12 mb-4">
            <div className="position-relative"></div>
          </div>
        </div>
        <div className="col-md-6 mb-4 d-flex justify-content-between">
          <button
            type="button"
            className="btn CancelBtn"
            onClick={() => {
              history.replace("./Sections");
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              editSections();
            }}
            type="button"
            className="btn submitBtn"
          >
            Submit
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdateSections;
