import React, { useState, useEffect, useMemo } from "react";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import Footer from "../Footer/footer";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "../Students/Students.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { MdModeEditOutline } from "react-icons/md";
import axios from "axios";
import Updateinfo from "./Updateinfo";
import ToggleButton from "../../components/Toggle_Button/ToggleButton";

function Info() {
  useEffect(() => {
    getinfosection();
  }, []);

  const SrNoRenderer = (props) => {
    return <span>{props.node.rowIndex + 1}</span>;
  };

  const PageRenderer = (props) => {
    return <span>{props.data.page}</span>;
  };

  const SectionRenderer = (props) => {
    return <span>{props.data.section}</span>;
  };

  const TextboxSrNoRenderer = (props) => {
    return <span>{props.data.number}</span>;
  };

  const Description = (props) => {
    return (
      <span dangerouslySetInnerHTML={{ __html: props.data.description }}></span>
    );
  };

  const statusrender = (props) => {
    return (
      <ToggleButton
        handleToggle={() => {
          changeStatus(props?.data?._id);
        }}
        status={props?.data?.status}
      />
    );
  };
  const ChildMessageRenderer = (props) => {
    return (
      <div className="iconActionList">
        <div
          className="editIcon"
          onClick={() => {
            setUpdateData(props.data);
            rowIndex(props.rowIndex);
          }}
          data-bs-toggle="modal"
          data-bs-target="#Updateinfo"
        >
          <MdModeEditOutline
            className="ActionIcon "
            // onClick={() => {
            //   setUpdateData(props.data);
            //   rowIndex(props.rowIndex);
            // }}
          />
        </div>
      </div>
    );
  };
  const rowHeight = 55;
  const [updateData, setUpdateData] = useState([]);
  const [sectionData, setSectionData] = useState([]);
  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);
  const [IndexValue, setIndexValue] = useState(0);
  const rowIndex = (index) => {
    setIndexValue(index);
  };

  function changeStatus(id) {
    let data = JSON.stringify({
      staticInfoId: id,
    });
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/changeinfosectionstatus`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getinfosection();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function getinfosection() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getinfosection`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setSectionData(response.data.sectionData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Information Text</h4>
              <div
                className="modal fade"
                id="Updateinfo"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                  <Updateinfo
                    updatedData={updateData}
                    getinfosection={getinfosection}
                    rowIndex={IndexValue}
                  />
                </div>
              </div>
              <div
                className="modal fade DeletePopup"
                id="BusinessDeleteId"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              ></div>
              <div
                style={{ width: "100%" }}
                className="ag-theme-alpine tableFix"
              >
                <AgGridReact
                  rowHeight={rowHeight}
                  rowData={sectionData}
                  defaultColDef={defaultColDef}
                  frameworkComponents={{
                    SrNoRenderer: SrNoRenderer,
                    page: PageRenderer,
                    section: SectionRenderer,
                    testboxsrno: TextboxSrNoRenderer,
                    childMessageRenderer: ChildMessageRenderer,
                    description: Description,
                    status: statusrender,
                  }}
                >
                  <AgGridColumn
                    width={100}
                    field="SrNo"
                    cellRenderer="SrNoRenderer"
                    sortable={false}
                    filter={false}
                  ></AgGridColumn>

                  <AgGridColumn
                    width={150}
                    field="Actions"
                    cellRenderer="childMessageRenderer"
                    sortable={true}
                    filter={true}
                  ></AgGridColumn>

                  <AgGridColumn
                    width={300}
                    field="page"
                    cellRenderer="Page"
                    sortable={true}
                    filter={true}
                  ></AgGridColumn>

                  <AgGridColumn
                    width={300}
                    field="section"
                    cellRenderer="section"
                    sortable={true}
                    filter={true}
                  ></AgGridColumn>

                  <AgGridColumn
                    width={180}
                    headerName="Textbox Sr No"
                    field="number"
                    cellRenderer="testboxsrno"
                    sortable={true}
                    filter={true}
                  ></AgGridColumn>
                  <AgGridColumn
                    width={250}
                    headerName="Description"
                    field="description"
                    cellRenderer="description"
                    sortable={true}
                    filter={true}
                  ></AgGridColumn>

                  <AgGridColumn
                    width={250}
                    field="Status"
                    cellRenderer="status"
                    sortable={true}
                    filter={true}
                  ></AgGridColumn>
                </AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
export default Info;
