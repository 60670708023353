import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./AddArticles.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import JoditEditor from "jodit-react";
import { useHistory, useLocation } from "react-router-dom";
import Compressor from "compressorjs";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

function UpdateArticles() {
  const defaultFonts = [
    "Arial",
    "Comic Sans MS",
    "Courier New",
    "Impact",
    "Georgia",
    "Tahoma",
    "Trebuchet MS",
    "Verdana",
  ];
  const sortedFontOptions = [
    "Logical",
    "Salesforce Sans",
    "Garamond",
    "Sans-Serif",
    "Serif",
    "Times New Roman",
    "Helvetica",
    ...defaultFonts,
  ].sort();
  const editor = useRef(null);
  let history = useHistory();
  let location = useLocation();
  const [addButtonStatus, setAddButtonStatus] = useState(null);
  const [content, setContent] = useState("");
  const [description, setDescription] = useState("");
  const [authorImage, setAuthorImage] = useState("");
  const [sendAuthorImage, setSendAuthorImage] = useState("");
  const [sendArticleImage, setSendArticleImage] = useState("");
  const [authorName, setauthorName] = useState("");
  const [authordescription, setAuthorDescription] = useState("");
  const [articleImage, setArticleImage] = useState("");
  const [title, setTitle] = useState("");
  const [subject, setSubject] = useState("");
  const [articleDescription, setArticleDescription] = useState("");
  const [options, setoptions] = useState([]);
  const [selectedOptionone, setselectedOptionone] = useState([]);
  const [selectedOptionTagLabel, setSelectedOptionTagLabel] = useState([]);
  const [selectedOptionTagLabelTagIds, setSelectedOptionTagLabelTagIds] =
    useState(
      location.state.details?.tags_Id?.map((item) => {
        return { value: item?.id, label: item?.tagName };
      })
    );
  // const [serviceList, setServiceList] = useState([{ image: "", article: "" }]);
  const [groupdata, setGroupdata] = useState([]);
  const [selectedMultipleGroups, setSelectedMultipleGroups] = useState(
    location.state.details.groups_Id.map((item, index) => {
      return { value: item.id, label: item.grpName };
    })
  );
  const [selectedOptiongroup, setselectedOptiongroup] = useState(
    location.state.details.groups_Id.map((item) => item)
  );
  const [selectedOptionGroupLabel, setSelectedOptionGroupLabel] = useState([]);
  const [priority, setPriority] = useState(""); // ----priority state---//
  const [group, setGroup] = useState([]); // THIS STATE IS USED FOR "GROUP" PARAMETER FOR SECTION API
  const [groupsName, setGroupsName] = useState([]); // THIS STATE IS USED FOR "GROUPSNAME" PARAMETER FOR SECTION API

  //----------EDIT ARTICLE API----------//

  function editArticles() {
    setAddButtonStatus(true);

    // --- THIS CONDITION IS USED FOR "GROUP" PARAMETER FOR SECTION API

    if (selectedOptionTagLabel.length === 0) {
      location.state.details.tags.map((item) => {
        groupsName.push(item);
      });
    } else {
      selectedOptionTagLabel.map((item) => {
        groupsName.push(item);
      });
    }
    if (selectedOptionGroupLabel.length === 0) {
      location.state.details.groupId.map((item) => {
        groupsName.push(item);
      });
    } else {
      selectedOptionGroupLabel.map((item) => {
        groupsName.push(item);
      });
    }

    if (selectedOptionTagLabel.length === 0) {
      location.state.details.tags.map((item) => {
        group.push(item);
      });
    } else {
      selectedOptionTagLabel.map((item) => {
        group.push(item);
      });
    }

    if (selectedOptionGroupLabel.length === 0) {
      location.state.details.groupId.map((item) => {
        group.push(item);
      });
    } else {
      selectedOptionGroupLabel.map((item) => {
        group.push(item);
      });
    }

    let data = new FormData();
    data.append(
      "priority",
      priority
        ? priority
        : location.state.details.priority
        ? location.state.details.priority
        : 0
    );
    data.append("group", group);
    data.append("groupsName", groupsName);
    data.append(
      "authorimage",
      sendAuthorImage == ""
        ? location.state.details.authorimage
        : sendAuthorImage
    );
    data.append(
      "authorName",
      authorName === "" ? location.state.details.authorName : authorName
    );
    data.append(
      "authordescription",
      authordescription === ""
        ? location.state.details.authordescription
        : authordescription
    );
    data.append(
      "title",
      title === "" || null ? location.state.details.title : title
    );
    data.append(
      "banner",
      sendArticleImage === "" ? location.state.details.banner : sendArticleImage
    );
    data.append(
      "subject",
      subject === "" ? location.state.details.subject : subject
    );
    data.append(
      "articledescription",
      articleDescription === ""
        ? location.state.details.articledescription
        : articleDescription
    );
    data.append(
      "description",
      content === "" ? location.state.details.description.toString() : content
    );
    data.append("articleId", location.state.details._id);
    data.append(
      "tags",
      selectedOptionTagLabel.toString() === "" || null
        ? location.state.details.tags
            .map((item) => {
              return item;
            })
            .toString()
        : selectedOptionTagLabel.toString()
    );
    data.append(
      "tags_Id",
      JSON.stringify(
        selectedOptionTagLabelTagIds.map((item) => {
          if ("id" in item) {
            return { id: item.id, tagName: item.tagName };
          }
          return { id: item.value, tagName: item.label };
        })
      )
    );

    console.log(selectedOptionTagLabelTagIds, "inside edit");

    data.append(
      "groupId",
      selectedOptionGroupLabel.toString() === "" || null
        ? location.state.details?.groupId
            ?.map((item) => {
              return item;
            })
            .toString()
        : selectedOptionGroupLabel
    );
    // data.append("groups_Id", selectedOptiongroup.map(item => item).toString());

    data.append(
      "groups_Id",
      JSON.stringify(
        selectedMultipleGroups.map((item) => {
          if (item.value) {
            return { id: item.value, grpName: item.label };
          }
          return item;
        })
      )
    );

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/editarticles`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "Application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setAddButtonStatus(false);
        history.push("./Articles");
      })
      .catch(function (error) {
        setAddButtonStatus(false);
        // console.log(error);
      });
  }

  //----get tags API--//

  useEffect(() => {
    getnewtag();
    getnewgroup();
  }, []);

  function getnewgroup() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getgroups`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        let groupData = [];
        response.data.data.map((item, index) => {
          groupData.push({ label: item.groups, value: item._id });
        });
        setGroupdata(groupData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  const handlechangegroupmulti = (event) => {
    let array = [];
    let multigroupArray = [];
    let arrayLabel = [];
    event.forEach((option) => {
      multigroupArray.push({ id: option.value, grpName: option.label });
      array.push(option.value);
    });
    event.forEach((option) => {
      arrayLabel.push(option.label);
    });
    setSelectedMultipleGroups(multigroupArray);
    setselectedOptiongroup(array);
    setSelectedOptionGroupLabel(arrayLabel);
  };

  function getnewtag() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/gettag`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let tagdata = [];
        response.data?.tagData?.forEach((item) => {
          tagdata.push({ value: item?._id, label: item.tags });
        });
        setoptions(tagdata);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }
  const handlechangetag = (event) => {
    let array = [];
    let arrayLabel = [];
    let arrayLabelids = [];
    event.forEach((option) => {
      array.push(option.value);
    });
    event.forEach((option) => {
      arrayLabel.push(option.label);
    });
    event.forEach((option) => {
      arrayLabelids.push({ id: option.value, tagName: option.label });
    });
    setselectedOptionone(array);
    setSelectedOptionTagLabel(arrayLabel);
    setSelectedOptionTagLabelTagIds(arrayLabelids);
  };

  // ---- Get article API ---//
  let config = {
    readonly: false,
    height: 400,
  };

  //--------Function to handle and set Author Image--------//

  const handleAuthorImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          let display = URL.createObjectURL(compressedResult);
          setAuthorImage(display);
          setSendAuthorImage(compressedResult);
        },
      });
    }
  };

  //--------Function to handle and set Article Banner Image--------//

  const handleArticleImage = (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          let display = URL.createObjectURL(compressedResult);
          setArticleImage(display);
          setSendArticleImage(compressedResult);
        },
      });
    }
  };

  //--------Function to render images--------//

  const renderImages = (image) => {
    return (
      <img
        style={{ width: "110px", height: "140px" }}
        src={image}
        key={image}
      />
    );
  };

  const optionspriority = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];

  function selectPriorityValue(event) {
    setPriority(event.value);
  }

  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid min_height">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Edit Articles</h4>

              <div className="branchData">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row bgBox mb-3">
                      <div className="col-md-2">
                        <label className="form-label">Author Image</label>
                        <ul className="imagesUpload">
                          <li style={{ width: "100%", height: "148px" }}>
                            <input
                              type="file"
                              accept="image/*,capture=camera"
                              capture="”camera"
                              onChange={handleAuthorImage}
                            />
                            {!sendAuthorImage ? (
                              <img
                                style={{ width: "100%", height: "148px" }}
                                src={location.state.details.authorimage}
                              />
                            ) : (
                              renderImages(authorImage)
                            )}
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-10">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Author Name</label>
                          <input
                            defaultValue={location.state.details.authorName}
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              setauthorName(e.target.value);
                            }}
                          />
                        </div>

                        <div className="col-md-12">
                          <label className="form-label">
                            Author Description
                          </label>
                          <textarea
                            type="textarea"
                            defaultValue={
                              location.state.details.authordescription
                            }
                            className="form-control"
                            onChange={(e) => {
                              setAuthorDescription(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="row bgBox mb-3">
                      <div className="col-md-2">
                        <label className="form-label">Article Image</label>
                        <ul className="imagesUpload">
                          <li style={{ width: "100%", height: "128px" }}>
                            <input
                              type="file"
                              accept="image/*,capture=camera"
                              capture="”camera"
                              onChange={handleArticleImage}
                            />
                            {!sendArticleImage ? (
                              <img
                                style={{ width: "100%", height: "128px" }}
                                src={location.state.details.banner}
                              />
                            ) : (
                              renderImages(articleImage)
                            )}
                            {/* {renderImages(articleImage)} */}
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-10">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Article Title</label>
                          <input
                            defaultValue={location.state.details.title}
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              setTitle(e.target.value);
                            }}
                          />
                        </div>

                        <div className="col-md-12">
                          <label className="form-label">Article Subject</label>
                          <input
                            defaultValue={location.state.details.subject}
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              setSubject(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <label className="form-label">
                          Brief Article Description
                        </label>
                        <textarea
                          type="textarea"
                          defaultValue={
                            location.state.details.articledescription
                          }
                          className="form-control"
                          onChange={(e) => {
                            setArticleDescription(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="services">
                    <div className="col-md-12">
                      <div className="row bgBox mb-3">
                        <div className="col-md-12">
                          <label className="form-label">
                            Article Description
                          </label>
                          <div className="App">
                            <SunEditor
                              width="100%"
                              plugin=""
                              height="100%"
                              defaultValue={location.state.details.description.toString()}
                              onChange={(event) => {
                                setContent(event);
                              }}
                              setOptions={{
                                buttonList: [
                                  ["undo", "redo"],
                                  ["font", "fontSize", "formatBlock"],
                                  ["paragraphStyle", "blockquote"],
                                  [
                                    "bold",
                                    "underline",
                                    "italic",
                                    "strike",
                                    "subscript",
                                    "superscript",
                                  ],
                                  ["fontColor", "hiliteColor"],
                                  ["align", "list", "lineHeight"],
                                  ["outdent", "indent"],
                                  ["fullScreen", "showBlocks", "codeView"],
                                  ["preview", "print"],
                                  ["removeFormat"],

                                  [
                                    ":i-More Misc-default.more_vertical",
                                    "image",
                                    "video",
                                    "audio",
                                    "link",
                                    "fullScreen",
                                    "showBlocks",
                                    "codeView",
                                    "preview",
                                    "print",
                                    "save",
                                    "template",
                                  ],
                                  [
                                    ":r-More Rich-default.more_plus",
                                    "table",
                                    "horizontalRule",
                                    "imageGallery",
                                  ],
                                ],
                                defaultTag: "div",
                                minHeight: "300px",
                                showPathLabel: false,
                                font: sortedFontOptions,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row bgBox mb-3">
                      <div className="col-md-12 mb-3">
                        <label className="form-label">Select Tags</label>
                        <Select
                          isMulti
                          defaultValue={selectedOptionTagLabelTagIds}
                          key={location?.state?.details?.tags_Id?.map(
                            (item, index) => {
                              return { Value: `tags_Id${index}` };
                            }
                          )}
                          options={options}
                          onChange={(e) => handlechangetag(e)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="row bgBox mb-3">
                      <div className="col-md-12 mb-3">
                        <label className="form-label">Select Groups</label>
                        <Select
                          isMulti
                          defaultValue={selectedMultipleGroups}
                          key={location.state.details?.groups_Id?.map(
                            (item) => {
                              return item.id;
                            }
                          )}
                          options={groupdata}
                          onChange={(e) => {
                            e.persist = () => {};
                            handlechangegroupmulti(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-3">
                    <label className="form-label">Select Priority</label>
                    <Select
                      defaultValue={optionspriority.map((item) => {
                        if (
                          location.state.details?.priority?.toString() ===
                          item.value
                        ) {
                          return item;
                        }
                      })}
                      key={optionspriority.map((item) => {
                        if (location.state.details.priority === item.value) {
                          return item.value;
                        }
                      })}
                      options={optionspriority}
                      name="Priority"
                      onChange={(event) => selectPriorityValue(event)}
                    />
                  </div>

                  <div className="col-md-12">
                    <button
                      type="button"
                      className="btn CancelBtn me-3"
                      onClick={() => {
                        history.push("./Articles");
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      disabled={addButtonStatus}
                      type="button"
                      className="btn submitBtn me-3"
                      onClick={editArticles}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default UpdateArticles;
