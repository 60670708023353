import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Students.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const UpdateStudents = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setcountryCode] = useState("");
  const [isCountryCode, setcuntrycode] = useState("");
  const [isDialCode, setDialCode] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const reset = () => {
    setName("");
    setEmail("");
    setmobileNo("");
    setcountryCode("");
  };

  useEffect(() => {
    props.onEditDataFunction();
  }, []);

  function updateStudentData() {
    let data = JSON.stringify({
      userId: props.updatedData?._id,
      name: name == "" ? props.updatedData?.name : name,
      email: email == "" ? props.updatedData?.email : email,
      mobileNo: mobileNo == "" ? props.updatedData?.mobileNo : mobileNo,
      // dialCode: isDialCode,
      countryCode:
        countryCode == "" ? props.updatedData?.countryCode : countryCode,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/editstudent`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        props.onEditDataFunction();
        reset();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  function phoneInput(value, data) {
    setmobileNo(value);
    setDialCode(`+${data.dialCode}`);
    setcuntrycode(`${data.countryCode}`);
  }

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Edit User
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={reset}
        ></button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-12 mb-3">
            <label className="form-label">Edit Name </label>
            <div className="position-relative">
              <input
                type="text"
                defaultValue={props.updatedData?.name}
                key={props.updatedData?.name}
                className="form-control"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-3">
            <label className="form-label">Edit Email Address </label>
            <div className="position-relative">
              <input
                type="text"
                defaultValue={props.updatedData?.email}
                key={props.updatedData?.email}
                className="form-control"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <div className="hint_box" style={{ display: "block" }}></div>
            </div>
          </div>
        </div>
        <div className="col-md-12 mb-3">
          <label className="form-label">User Mobile Number</label>
          <div className="position-relative">
            <PhoneInput
              country={props.updatedData?.countryCode}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: true,
              }}
              value={props.updatedData?.mobileNo}
              onChange={phoneInput}
              defaultErrorMessage="It doesn't works, why?"
            />
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="submit"
          className="btn  CancelBtn"
          data-bs-dismiss="modal"
          onClick={reset}
        >
          Cancel
        </button>
        <button
          type="submit"
          onClick={updateStudentData}
          className="btn submitBtn"
          data-bs-dismiss="modal"
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default UpdateStudents;
