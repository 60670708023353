import React, { useState, useEffect } from "react";
import "./login.css";
import { useHistory } from "react-router-dom";
import axios from "axios";

function Login() {
  const [coord, setCoord] = useState("");
  sessionStorage.removeItem("_expiredTime");

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setCoord(position.coords);
    });
  }, []);

  let history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setmessage] = useState("");
  const [pvisibility, setpvisibility] = useState(false);
  const [ipAddress, setIP] = useState("");

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    getData();
  }, []);

  function signin(e) {
    e.preventDefault();

    let data = JSON.stringify({
      email: email,
      password: password,
      ipAddress: ipAddress,
      latitude: coord === "" ? 0 : coord?.latitude,
      longitude: coord === "" ? 0 : coord.longitude,
      date: new Date(),
      adminVerification: false,
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/adminlogin`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.data.status === 200) {
          setTimeout(() => {
            history.replace("/verification", {
              _id: response.data?.data?._id,
            });
          }, 1000);
        } else {
          if (response.data.status === 201) {
            setmessage(response.data.message);
          } else {
            setmessage("please enter correct credentials");
          }
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  return (
    <section className="login_section">
      <div className="form_box">
        <form onSubmit={signin}>
          <div className="col-md-12 text-center">
            <img className="logoImg" src="../../images/logo.png" />
          </div>
          <h4>Sign In</h4>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            className="form-control"
            placeholder="Email"
          />
          <div className="position-relative">
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={pvisibility ? "text" : "password"}
              className="form-control"
              placeholder="Password"
            />

            {pvisibility ? (
              <div>
                <img
                  style={{
                    width: "23px",
                    position: "absolute",
                    top: "13px",
                    right: "12px",
                  }}
                  src="images/eye.png"
                  onClick={() => {
                    setpvisibility(false);
                  }}
                />
              </div>
            ) : (
              <div>
                <img
                  style={{
                    width: "23px",
                    position: "absolute",
                    top: "13px",
                    right: "12px",
                  }}
                  src="images/invisible.png"
                  onClick={() => {
                    setpvisibility(true);
                  }}
                />
              </div>
            )}
          </div>
          <button type="submit" className="form-control btn">
            Login
          </button>
          {message}
        </form>
      </div>
    </section>
  );
}

export default Login;
