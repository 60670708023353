import React, { useState, useMemo, useEffect, useRef } from "react";
import Footer from "../Footer/footer";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import axios from "axios";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import LoadingSpinner from "../../components/loader";
import JsonDownload from "../../components/downloadjson";
import { useHistory } from "react-router-dom";
import isdownloadUtils from "../../components/utilDownloadRoles";

function Branch() {
  const gridRef = useRef();
  const history = useHistory();
  const rowheight = 55;
  const defaultcoldef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      filter: true,
      getQuickFilterText: (params) => {
        return params.value.name;
      },
      editable: true,
    };
  }, []);
  const [branchdata, setbranchdata] = useState([]);
  const [branchdownloaddata, setbranchDownloadData] = useState([]);
  const [updateuploadStatus, setUpdateUploadStatus] = useState("");
  const [duplicatedatastatus, setduplicatedatastatus] = useState(false);
  const [duplicateData, setDuplicatedata] = useState([]);

  const SrNoRenderer = (props) => {
    return <span>{props.node.rowIndex + 1}</span>;
  };
  const BusinessnameRenderer = (props) => {
    return <span>{props.data.admin_id?.businessName}</span>;
  };
  const BranchnameRenderer = (props) => {
    return <span>{props.data.branchName}</span>;
  };
  const AddressRenderer = (props) => {
    return <span>{props.data.address1}</span>;
  };
  const EmailRenderer = (props) => {
    return <span>{props.data.businessemail}</span>;
  };
  const PhoneRenderer = (props) => {
    return <span>{props.data.businessmobileNo}</span>;
  };
  const BranchadminnameRenderer = (props) => {
    return <span>{props.data.admin_id?.name}</span>;
  };
  const BranchadminemailRenderer = (props) => {
    return <span>{props.data.admin_id?.email}</span>;
  };
  const BranchadminphoneRenderer = (props) => {
    return <span>{props.data.admin_id?.mobileNo}</span>;
  };

  const businessnameValueGetter = (params) => {
    return params.data.admin_id?.businessName;
  };
  const branchnameValueGetter = (params) => {
    return params.data.branchName;
  };

  function getbranch() {
    gridRef?.current?.api?.showLoadingOverlay();
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getbranch`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let branchdata = [];
        gridRef?.current?.api?.hideOverlay();
        response.data.branchData.forEach((element) => {
          branchdata.push({
            "Business Name": element?.admin_id?.businessName,
            "Branch Name": element?.branchName,
            "Branch Type": element?.branchstatus,
            "Branch Admin Name": element?.name,
            id: element?._id,
            admin_id: element?.admin_id?._id,
            "Admin type": element?.admintype,
            "Branch Email": element?.businessemail,
            "Branch Admin Email": element?.email,
            "Branch PhoneNo": element?.businessmobileNo,
            Country: element?.country,
            "Web Address": element?.webAddress,
            "Branch Admin Phone No": element?.mobileNo,
            "Postal Code": element?.postalCode,
            Address1: element?.address1,
            Address2: element?.address2,
            lat: element?.loc.coordinates[1],
            long: element?.loc.coordinates[0],
          });
        });
        setbranchdata(response.data.branchData);
        setbranchDownloadData(branchdata);
      })
      .catch(function (error) {
        gridRef?.current?.api?.hideOverlay();
        // console.log(error);
      });
  }
  useEffect(() => {
    getbranch();
  }, []);

  // const [isOpen, setIsOpen] = useState(false);
  const [uploadIsOpen, setUploadIsOpen] = useState(false);
  const [duplicate, setDuplicate] = useState([]);
  // const [error, setError] = useState("")
  const { reset } = useForm({ mode: "onBlur" });
  const {
    register: uploadRegister,
    unregister,
    handleSubmit: handlesubmitUpload,
    formState: { errors: uploaderrors },
  } = useForm({ mode: "onBlur" });

  function somefun() {
    // setIsOpen(false);
    setUploadIsOpen(false);
    setUploadIsOpenStatusModal(false);
    setDuplicate([]);
    cross();
  }

  function fun() {
    // setIsOpen(false);
    setUploadIsOpen(false);
    setLoader(false);
    setDuplicate([]);
    cross();
  }

  function cross() {
    setLoader(false);
    setDuplicate([]);
    reset();
  }

  // --------- business bulk upload ------------ //
  const [uplaodStatus, setUplaodStatus] = useState({
    status: false,
    value: "",
  });
  const [uploadIsOpenStatusModal, setUploadIsOpenStatusModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [SuccessfullUpdateStatus, setSuccessfullUpdateStatus] = useState(true);

  function uploadBusinessFiles(postdata) {
    setLoader(true);
    let data = new FormData();
    data.append("attachment", postdata.uploadFile[0]);
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/${
        updateuploadStatus == "Upload" ? "branchupload" : "branchupdate"
      }`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        reset();
        getbranch();
        setUploadIsOpen(false);
        setUploadIsOpenStatusModal(true);
        setUplaodStatus({
          status: response.data.status,
          value: response.data.message,
        });
        if (response.data.status && updateuploadStatus == "Upload") {
          history.push({
            pathname: "DuplicateBranch",
            state: { details: response.data.duplicate },
          });
        }

        setTimeout(() => {
          if (response.data.status) {
            setduplicatedatastatus(true);
            reset();
            getbranch();
            let updated = [];
            let BalankID_or_wrongformat = [];
            let Blannklat = [];
            let Blannklong = [];
            let wrongEmailFormat = [];
            let DuplicateBranchname = [];
            let WrongID = [];
            let AlredyexistBranchName = [];

            response.data.updated &&
              response.data.updated.forEach((element) => {
                updated.push({ ...element, errorName: "Updated data" });
              });
            response.data.BalankID_or_wrongformat &&
              response.data.BalankID_or_wrongformat.forEach((element) => {
                BalankID_or_wrongformat.push({
                  ...element,
                  errorName: "Blank ID or Wrong format data",
                });
              });
            response.data.Blannklat &&
              response.data.Blannklat.forEach((element) => {
                Blannklat.push({ ...element, errorName: "Blank lat" });
              });

            response.data.Blannklong &&
              response.data.Blannklong.forEach((element) => {
                Blannklong.push({ ...element, errorName: "Blank long" });
              });
            response.data.wrongEmailFormat &&
              response.data.wrongEmailFormat.forEach((element) => {
                wrongEmailFormat.push({
                  ...element,
                  errorName: "Wrong Email Format",
                });
              });
            response.data.DuplicateBranchname &&
              response.data.DuplicateBranchname.forEach((element) => {
                DuplicateBranchname.push({
                  ...element,
                  errorName: "Duplicate Branchname",
                });
              });
            response.data.WrongID &&
              response.data.WrongID.forEach((element) => {
                WrongID.push({ ...element, errorName: "Wrong ID" });
              });
            response.data.AlredyexistBranchName &&
              response.data.AlredyexistBranchName.forEach((element) => {
                AlredyexistBranchName.push({
                  ...element,
                  errorName: "Already Existing BranchName",
                });
              });

            const updateBusinessStatus =
              !updated.length &&
              !BalankID_or_wrongformat.length &&
              !Blannklat.length &&
              !Blannklong.length &&
              !wrongEmailFormat.length &&
              !DuplicateBranchname.length &&
              !WrongID.length &&
              !AlredyexistBranchName.length;

            if (updateBusinessStatus) {
              setSuccessfullUpdateStatus(false);
            }
            setDuplicatedata([
              ...updated,
              ...BalankID_or_wrongformat,
              ...Blannklat,
              ...Blannklong,
              ...wrongEmailFormat,
              ...DuplicateBranchname,
              ...WrongID,
              ...AlredyexistBranchName,
            ]);
          }
        }, 1000);
      })
      .catch(function (error) {
        fun();
        setUploadIsOpenStatusModal(true);
        setUplaodStatus({
          status: false,
          value: "Something went wrong",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setUploadIsOpenStatusModal(false);
        }, 1000);
      });
  }

  return (
    <>
      <Header />
      <Sidebar />
      {!duplicatedatastatus && (
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  Branch
                  <div className="float-end btns_head d-flex">
                    {isdownloadUtils("uploadBranch") && (
                      <button
                        className="btn btn-theme btn-sm"
                        onClick={() => {
                          setUploadIsOpen(true);
                          setUpdateUploadStatus("Upload");
                        }}
                      >
                        Upload Branch
                      </button>
                    )}
                    {isdownloadUtils("updateBusinesses") && (
                      <button
                        className="btn btn-theme btn-sm"
                        onClick={() => {
                          setUploadIsOpen(true);
                          setUpdateUploadStatus("Update");
                        }}
                      >
                        Update Branch
                      </button>
                    )}
                    {isdownloadUtils("downloadBranch") && (
                      <JsonDownload
                        title="Download Branch"
                        fileName="branch-excel"
                        downloadfile={branchdownloaddata}
                        btnClassName={"btn btn-theme btn-sm"}
                        btnColor={"#f9560f"}
                      />
                    )}
                    {/* <button className="btn btn-theme btn-sm">Download Excel</button> */}
                  </div>
                </h4>
                <div>
                  <Modal show={uploadIsOpenStatusModal} onHide>
                    <Modal.Body>
                      <div
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                <p>
                                  <strong
                                    style={{
                                      color: uplaodStatus.status
                                        ? "green"
                                        : "red",
                                    }}
                                  >
                                    {uplaodStatus.value}
                                  </strong>
                                </p>
                                {/* <p> Status:<strong style={{ 'color': uplaodStatus.status ? 'green' : 'red' }}>{uplaodStatus.status}</strong></p> */}
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                  somefun();
                                }}
                                // onClick={resetForm}
                              ></button>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                {duplicate.length > 0 && (
                                  <div className="col-md-12 mb-4">
                                    <label className="form-label">
                                      Email Already Exists
                                    </label>
                                    <ul>
                                      {duplicate?.map((item) => {
                                        return <li>{item}</li>;
                                      })}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
                <div>
                  <Modal show={uploadIsOpen} onHide>
                    <Modal.Body>
                      <div
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <form
                          onSubmit={handlesubmitUpload(uploadBusinessFiles)}
                        >
                          <div className="modal-dialog modal-dialog-scrollable">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="exampleModalLabel"
                                >
                                  {updateuploadStatus} File
                                </h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={() => {
                                    fun();
                                  }}
                                  // onClick={resetForm}
                                ></button>
                              </div>
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-md-12 mb-4">
                                    <label className="form-label">
                                      {" "}
                                      Upload File
                                    </label>
                                    <div className="position-relative">
                                      <input
                                        className="form-control"
                                        type="file"
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        {...uploadRegister("uploadFile", {
                                          required: true,
                                        })}
                                        onChange={(e) => {
                                          unregister("uploadFile");
                                        }}
                                      />
                                      {uploaderrors?.uploadFile?.type ===
                                        "required" && (
                                        <p className="error">
                                          This field is required
                                        </p>
                                      )}
                                      <div
                                        className="hint_box"
                                        style={{ display: "block" }}
                                      ></div>
                                    </div>
                                  </div>
                                  {duplicate.length > 0 && (
                                    <div className="col-md-12 mb-4">
                                      <label className="form-label">
                                        Email Already Exists
                                      </label>
                                      <ul>
                                        {duplicate?.map((item) => {
                                          return <li>{item}</li>;
                                        })}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {loader && <LoadingSpinner />}
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn CancelBtn"
                                  data-bs-dismiss="modal"
                                  onClick={() => {
                                    somefun();
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn submitBtn"
                                  disabled={loader}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
                <div></div>

                <div
                  style={{ width: "100%" }}
                  className="ag-theme-alpine tableFix"
                >
                  <AgGridReact
                    rowHeight={rowheight}
                    rowData={branchdata}
                    defaultColDef={defaultcoldef}
                    frameworkComponents={{
                      srnoRenderer: SrNoRenderer,
                      businessnameRenderer: BusinessnameRenderer,
                      branchnameRenderer: BranchnameRenderer,
                      addressRenderer: AddressRenderer,
                      emailRenderer: EmailRenderer,
                      phoneRenderer: PhoneRenderer,
                      branchadminnameRenderer: BranchadminnameRenderer,
                      branchadminemailRenderer: BranchadminemailRenderer,
                      branchadminphoneRenderer: BranchadminphoneRenderer,
                    }}
                    onGridReady={getbranch}
                    overlayLoadingTemplate={
                      '<span class="ag-overlay-loading-center" style="margin: auto">Please wait while your rows are loading</span>'
                    }
                    ref={gridRef}
                    overlayNoRowsTemplate={
                      "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                    }
                  >
                    <AgGridColumn
                      field="SrNo"
                      width={80}
                      cellRenderer="srnoRenderer"
                      sortable={false}
                      filter={false}
                      pinned="left"
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Business Name"
                      field="businessname"
                      width={150}
                      cellRenderer="businessnameRenderer"
                      sortable={true}
                      filter={true}
                      valueGetter={businessnameValueGetter}
                      pinned="left"
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Branch Name"
                      field="branchName"
                      width={140}
                      cellRenderer="branchnameRenderer"
                      sortable={true}
                      filter={true}
                      valueGetter={branchnameValueGetter}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Branch Type"
                      field="branchstatus"
                      width={140}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Branch Email"
                      field="businessemail"
                      width={140}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Branch PhoneNo"
                      field="businessmobileNo"
                      width={158}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Web Address"
                      field="webAddress"
                      width={150}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Postal Code"
                      field="postalCode"
                      width={150}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Address1"
                      field="address1"
                      width={120}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Address2"
                      field="address2"
                      width={120}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="BranchAdminName"
                      field="name"
                      width={180}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="BranchAdminEmail"
                      field="email"
                      width={180}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Branch Admin Phone No"
                      field="mobileNo"
                      width={190}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Admin type"
                      field="admintype"
                      width={130}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      field="country"
                      width={120}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {duplicatedatastatus && (
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  {updateuploadStatus == "Update" && "Updated Branch Error Log"}
                  <div className="float-end btns_head d-flex">
                    <button
                      className="btn btn-theme btn-sm"
                      onClick={() => {
                        setduplicatedatastatus(false);
                        setLoader(false);
                        setUploadIsOpenStatusModal(false);
                      }}
                    >
                      Back
                    </button>

                    <JsonDownload
                      title="Download Branch"
                      fileName="errorlog-branch-excel"
                      downloadfile={duplicateData}
                      btnClassName={"btn btn-theme btn-sm"}
                      btnColor={"#f9560f"}
                    />
                  </div>
                </h4>

                <div
                  style={{ width: "100%" }}
                  className="ag-theme-alpine tableFix"
                >
                  <AgGridReact
                    rowHeight={rowheight}
                    rowData={duplicateData}
                    defaultColDef={defaultcoldef}
                    frameworkComponents={{
                      srnoRenderer: SrNoRenderer,
                    }}
                    onGridReady={getbranch}
                    overlayLoadingTemplate={
                      '<span class="ag-overlay-loading-center" style="margin: auto">Please wait while your rows are loading</span>'
                    }
                    ref={gridRef}
                    overlayNoRowsTemplate={
                      "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow\">This is a custom 'no rows' overlay</span>"
                    }
                  >
                    <AgGridColumn
                      field="SrNo"
                      width={80}
                      cellRenderer="srnoRenderer"
                      sortable={false}
                      filter={false}
                      pinned="left"
                    ></AgGridColumn>
                    <AgGridColumn
                      width={150}
                      field="errorName"
                      sortable={false}
                      filter={false}
                      pinned="left"
                    ></AgGridColumn>

                    <AgGridColumn
                      headerName="Branch Name"
                      field="branchName"
                      width={140}
                      cellRenderer="branchnameRenderer"
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Branch Status"
                      field="branchstatus"
                      width={150}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Id"
                      field="_id"
                      width={140}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Admin Type"
                      field="admintype"
                      width={140}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Business Email"
                      field="businessemail"
                      width={158}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Email"
                      field="email"
                      width={150}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Business Mobile No."
                      field="businessmobileNo"
                      width={150}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="country"
                      field="country"
                      width={120}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="webAddress"
                      field="webAddress"
                      width={120}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Mobile No"
                      field="mobileNo"
                      width={180}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Postal Code"
                      field="postalCode"
                      width={180}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="Name"
                      field="name"
                      width={190}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="lat"
                      field="lat"
                      width={130}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="long"
                      field="long"
                      width={120}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                    <AgGridColumn
                      headerName="loc"
                      field="loc"
                      width={120}
                      sortable={true}
                      filter={true}
                    ></AgGridColumn>
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}
export default Branch;
