import React, { useState, useEffect } from "react";
import axios from "axios";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./AddEvents.css";
import Footer from "../Footer/footer";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import RenderImages from "../../components/RenderImages";
import { useForm } from "react-hook-form";
import Geocode from "react-geocode";
import Compressor from "compressorjs";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyCs3aM7dZ9UsKdxW-0K5iQUDWKz4Fyi-Cg");

// set response language. Defaults to english.
Geocode.setLanguage("en");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("es");

// set location_type filter . Its optional.
// google geocoder returns more that one address for given lat/lng.
// In some case we need one address as response for which google itself provides a location_type filter.
// So we can easily parse the result for fetching address components
// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
Geocode.setLocationType("ROOFTOP");

// Enable or disable logs. Its optional.
Geocode.enableDebug();

function AddEvents() {
  const defaultFonts = [
    "Arial",
    "Comic Sans MS",
    "Courier New",
    "Impact",
    "Georgia",
    "Tahoma",
    "Trebuchet MS",
    "Verdana",
  ];
  const sortedFontOptions = [
    "Logical",
    "Salesforce Sans",
    "Garamond",
    "Sans-Serif",
    "Serif",
    "Times New Roman",
    "Helvetica",
    ...defaultFonts,
  ].sort();

  const [content, setContent] = useState("");
  const config = {
    readonly: false,
    height: 400,
  };

  const currentcyFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const [value, setValue] = useState("");

  const keyPressHanlder = (event) => {
    const { key } = event;
    setValue((prevValue) =>
      key !== "Backspace"
        ? !Number.isNaN(parseInt(key)) || key === "," || key === "."
          ? prevValue + key
          : prevValue
        : prevValue.substring(0, prevValue.length - 1)
    );
  };
  const [minDate, setMinDate] = useState(null);
  let latitude, longitude;
  const [eventType, setEventType] = useState("");
  const [priceType, setpriceType] = useState("");
  const [sendEventImage, setSendEventImage] = useState([]);
  const [optionsgroup, setoptionsgroup] = useState([]);
  const [selectedOptiongroup, setselectedOptiongroup] = useState([]);
  const [selectedMultipleGroups, setSelectedMultipleGroups] = useState([]);
  const [selectedOptionGroupLabel, setSelectedOptionGroupLabel] = useState([]);
  const [postalCode, setpostalCode] = useState(false);
  const [address1, setaddress1] = useState("");
  const [country, setcountry] = useState("Singapore");
  const [options, setoptions] = useState([]);

  const [priority, setPriority] = useState("");
  const [selectedOptionone, setselectedOptionone] = useState([]);
  const [selectedOptionTagLabel, setSelectedOptionTagLabel] = useState([]);
  const [selectedOptionTagLabelTagIds, setSelectedOptionTagLabelTagIds] =
    useState([]);
  const [group, setGroup] = useState([]); // THIS STATE IS USED FOR "GROUP" PARAMETER FOR SECTION API
  const [groupsName, setGroupsName] = useState([]); // THIS STATE IS USED FOR "GROUPSNAME" PARAMETER FOR SECTION API
  const [upload, setUpload] = useState({
    pictures: [],
    maxFileSize: 5242880,
    imgExtension: [".jpg", ".png", "jpeg"],
    defaultImages: [],
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  let history = useHistory();

  //------------React-image-upload --------//

  let handleChange = (pictureFiles, files) => {
    for (let i = 0; i < pictureFiles.length; i++) {
      let file = pictureFiles[i];
      new Compressor(file, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          // let display = URL.createObjectURL(compressedResult);
          // setAuthorImage(display);
          // setSendAuthorImage(compressedResult);
          setSendEventImage((prev) => [...prev, compressedResult]);
        },
      });
    }
    const { pictures } = upload;

    setUpload({
      ...upload,
      pictures: [...pictures, ...files],
    });
  };

  useEffect(() => {
    getnewgroup();
    getnewtag();
  }, []);

  const getlatlong = () => {
    Geocode.fromAddress(address1).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        latitude = lat;
        longitude = lng;
      },
      (error) => {
        // console.error(error);
      }
    );
  };
  getlatlong();

  // ----Add Event API ---//
  function AddEvent(postdata) {
    selectedOptionGroupLabel.map((item) => {
      groupsName.push(item);
    });

    selectedOptionTagLabel.map((item) => {
      groupsName.push(item);
    });

    selectedOptionone.map((item) => {
      group.push(item);
    });
    selectedOptiongroup.map((item) => {
      group.push(item);
    });

    let data = new FormData();
    for (let i = 0; i < sendEventImage.length; i++) {
      data.append("image", sendEventImage[i]);
    }

    data.append("groupId", selectedOptionGroupLabel);
    data.append("title", postdata.title);
    data.append("Date", postdata.startdate);
    data.append("endDate", postdata.enddate);
    data.append("price", priceType == "Price" ? value : priceType);
    data.append("startTime", postdata.startTime);
    data.append("endTime", postdata.endTime);
    data.append("seteventtype", postdata.eventtype);
    data.append("description", content);
    data.append("postalCode", postdata.postalCode);
    data.append("address1", address1);
    data.append("lat", latitude ? latitude : 0);
    data.append("long", longitude ? longitude : 0);
    data.append("address2", postdata.address2);
    data.append("country", country);
    data.append("contactName", postdata.contactName);
    data.append("contactEmail", postdata.contactEmail);
    data.append("contactPhone", "+65" + postdata.contactPhone);
    data.append("tags", selectedOptionTagLabel);
    data.append("tags_Id", JSON.stringify(selectedOptionTagLabelTagIds));
    data.append("priority", priority);
    data.append("smallDescription", postdata.smallDescription);
    data.append("group", group);
    data.append("groupsName", groupsName);
    data.append("groups_Id", JSON.stringify(selectedMultipleGroups));

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/addevent`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        history.push("/Events");
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  // ----multi group selete get--//

  function getnewgroup() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/getgroups`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        let optionsgroup = [];
        response.data.data.forEach((grup, index) => {
          optionsgroup.push({ label: grup.groups, value: grup._id });
        });
        setoptionsgroup(optionsgroup);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const handlechangegroupmulti = (event) => {
    let array = [];
    let multigroupArray = [];
    let arrayLabel = [];
    event.forEach((option) => {
      array.push(option.value);
      multigroupArray.push({ id: option.value, grpName: option.label });
    });
    event.forEach((option) => {
      arrayLabel.push(option.label);
    });
    setSelectedMultipleGroups(multigroupArray);
    setselectedOptiongroup(array);
    setSelectedOptionGroupLabel(arrayLabel);
  };

  // ----PostelCode API--//

  // ---- get Postal Code API ---//
  function getAddressPostcode(postalCode) {
    if (postalCode?.length === 6) {
      let data = JSON.stringify({
        postalCode,
      });

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_BASEURL}/getAddressPostcode`,
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          const address =
            response.data.addressfrompostcode?.address[0].ADDRESS.slice(
              0,
              response.data.addressfrompostcode?.address[0].ADDRESS.lastIndexOf(
                "SINGAPORE"
              )
            );
          setaddress1(address);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }

  // ---- On Enter key Calling API ---//
  const postalCodeChangeHandle = (e) => {
    if (e.target?.value?.length === 6) {
      getAddressPostcode(e.target.value);
    }
  };
  // ----multi tags selete get--//

  function getnewtag() {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/gettag`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        response.data.tagData.forEach((tag, index) => {
          options.push({ value: tag._id, label: tag.tags });
        });
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  const handlechangetag = (event) => {
    let array = [];
    let arrayLabel = [];
    let arrayLabelids = [];
    event.forEach((option) => {
      array.push(option.value);
    });
    event.forEach((option) => {
      arrayLabel.push(option.label);
    });
    event.forEach((option) => {
      arrayLabelids.push({ id: option.value, tagName: option.label });
    });
    setselectedOptionone(array);
    setSelectedOptionTagLabel(arrayLabel);
    setSelectedOptionTagLabelTagIds(arrayLabelids);
  };

  const optionspriority = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];

  function selectPriorityValue(event) {
    setPriority(event.value);
  }

  function pricefield(events) {
    setpriceType(events);
  }

  const checkHandler = (events) => {
    setEventType(events);
  };

  return (
    <>
      <Header />
      <Sidebar />
      <form onSubmit={handleSubmit(AddEvent)}>
        <div className="page-wrapper">
          <div className="container-fluid min_height">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Add Event</h4>

                <div className="branchData">
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label className="form-label">event Image</label>
                      <div className="App">
                        <RenderImages {...upload} handleChange={handleChange} />
                      </div>
                    </div>

                    <div className="col-md-6 mb-3">
                      {<label className="form-label">Select Group</label>}
                      <Select
                        isMulti
                        options={optionsgroup}
                        onChange={(e) => handlechangegroupmulti(e)}
                      />
                      {/* <label className="form-label">Select Group</label>
                    <Select
                     isMulti   
                    options={optionsgroup}
                    onChange={handlechangegroupmulti}  {...register("Select Group")} /> */}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Event Title</label>
                      {/* <input
                      value={title}
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        settitle(e.target.value);
                      }}
                    />{" "} */}
                      <input
                        type="text"
                        className="form-control"
                        {...register("title", { required: true })}
                      />
                      {errors?.title?.type === "required" && (
                        <p className="error">This field is required</p>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Event Start Date</label>
                      {/* <input
                      value={Date}
                      type="Date"
                      className="form-control"
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                    />{" "} */}
                      <input
                        className="form-control"
                        type="date"
                        {...register("startdate", {
                          required: true,
                          onChange: (e) =>
                            setMinDate(e.target.value, "startdate"),
                        })}
                      />
                      {errors?.startdate?.type === "required" && (
                        <p className="error">This field is required</p>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Event End Date</label>

                      <input
                        className="form-control"
                        type="date"
                        min={minDate}
                        {...register("enddate", { required: true })}
                      />
                      {errors?.enddate?.type === "required" && (
                        <p className="error">This field is required</p>
                      )}
                    </div>

                    <div className="col-md-6 mb-3">
                      <label className="form-label">Event Start Time</label>
                      {/* <input
                      value={startTime}
                      type="time"
                      className="form-control"
                      onChange={(e) => {
                        setstartTime(e.target.value);
                      }}
                    />{" "} */}
                      <input
                        className="form-control"
                        type="time"
                        {...register("startTime", { required: false })}
                      />
                      {/* {errors?.startTime?.type === "required" && (
                        <p className="error">This field is required</p>
                      )} */}
                    </div>

                    <div className="col-md-6 mb-3">
                      <label className="form-label">Event End Time</label>
                      {/* <input
                      value={endTime}
                      type="time"
                      className="form-control"
                      onChange={(e) => {
                        setendTime(e.target.value);
                      }}
                    />{" "} */}
                      <input
                        className="form-control"
                        type="time"
                        {...register("endTime", { required: false })}
                      />
                      {/* {errors?.endTime?.type === "required" && (
                        <p className="error">This field is required</p>
                      )} */}
                    </div>

                    <div className="col-md-6 mb-3">
                      <label className="form-label">Event Price</label>
                      {/*                  
                      <input type="text"
                      className="form-control" 
                      onKeyDown={keyPressHanlder}
                     placeholder="$"
                      value={value !== "" ? currentcyFormat.format(value) : ""} {...register("price",{required:true})} />
                     {errors?.price?.type === "required" && (<p>This field is required</p>)}  */}
                      <div className="d-flex justify-content-center">
                        <label className="container">
                          Free
                          <input
                            {...register("price", { required: false })}
                            type="radio"
                            value="free"
                            onClick={() => {
                              pricefield("free");
                            }}
                          />
                          <span className="check"></span>
                        </label>
                        <label className="container">
                          Price
                          <input
                            {...register("price", { required: false })}
                            type="radio"
                            value="Price"
                            onClick={() => {
                              pricefield("Price");
                            }}
                          />
                          <span className="check"></span>
                        </label>
                      </div>
                      {/* {errors?.price?.type === "required" && (
                        <p className="error">Select Event Price</p>
                      )} */}
                      {priceType == "Price" ? (
                        <div className="col-md-12 mb-3">
                          <input
                            type="text"
                            className="form-control"
                            onKeyDown={keyPressHanlder}
                            placeholder="$"
                            value={
                              value !== "" ? currentcyFormat.format(value) : ""
                            }
                            {...register("rupees", { required: true })}
                          />
                          {/* {errors?.price?.type === "required" && (
                            <p className="error">This field is required</p>
                          )} */}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-12 mt-3">
                      <label className="form-label">
                        Brief Event Description
                      </label>
                      <textarea
                        type="textarea"
                        className="form-control"
                        {...register("smallDescription", { required: true })}
                      />
                      {errors?.smallDescription?.type === "required" && (
                        <p className="error">This field is required</p>
                      )}
                    </div>

                    <div className="col-md-12 mb-3">
                      <label className="form-label">Event Description</label>
                      <div className="App">
                        <SunEditor
                          width="100%"
                          plugin=""
                          height="100%"
                          onChange={(event) => setContent(event)}
                          setOptions={{
                            buttonList: [
                              ["undo", "redo"],
                              ["font", "fontSize", "formatBlock"],
                              ["paragraphStyle", "blockquote"],
                              [
                                "bold",
                                "underline",
                                "italic",
                                "strike",
                                "subscript",
                                "superscript",
                              ],
                              ["fontColor", "hiliteColor"],
                              ["align", "list", "lineHeight"],
                              ["outdent", "indent"],
                              ["fullScreen", "showBlocks", "codeView"],
                              ["preview", "print"],
                              ["removeFormat"],

                              [
                                ":i-More Misc-default.more_vertical",
                                "image",
                                "video",
                                "audio",
                                "link",
                                "fullScreen",
                                "showBlocks",
                                "codeView",
                                "preview",
                                "print",
                                "save",
                                "template",
                              ],
                              [
                                ":r-More Rich-default.more_plus",
                                "table",
                                "horizontalRule",
                                "imageGallery",
                              ],
                            ],
                            defaultTag: "div",
                            minHeight: "300px",
                            showPathLabel: false,
                            font: sortedFontOptions,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3 ">
                      <div className="">
                        <label className="form-label">
                          Select Event Type :
                        </label>
                        <input
                          {...register("eventtype", { required: true })}
                          type="radio"
                          value="Online"
                          checked={eventType === "Online"}
                          onClick={() => {
                            checkHandler("Online");
                          }}
                          style={{ marginLeft: "10px", marginRight: "3px" }}
                        />
                        <label for="On-line">On-line</label>

                        <input
                          {...register("eventtype", { required: true })}
                          type="radio"
                          value="Inperson"
                          checked={eventType === "Inperson"}
                          onClick={() => {
                            checkHandler("Inperson");
                          }}
                          style={{ marginLeft: "40px", marginRight: "5px" }}
                        />
                        <label for="In-person">In-person</label>
                      </div>
                      {errors?.eventtype?.type === "required" && (
                        <p className="error">Select at least One Event Type</p>
                      )}
                      {eventType === "Inperson" && (
                        <div className="col-md-12 mb-3 d-flex justify-content-around">
                          <div
                            className="col-md-3"
                            style={{ marginRight: "10px" }}
                          >
                            <label className="form-label">Postal Code</label>
                            <input
                              // defaultvalue={postalCode}
                              type="tel"
                              name="postalCode"
                              onKeyUp={postalCodeChangeHandle}
                              className="form-control"
                              {...register("postalCode", {
                                required: true,
                                maxLength: 6,
                                minLength: 6,
                              })}
                            />
                            {errors?.postalCode?.type === "required" && (
                              <p className="error">This field is required</p>
                            )}
                            {errors?.postalCode?.type === "maxLength" && (
                              <p className="error">Only 6 digit number</p>
                            )}
                            {errors?.postalCode?.type === "minLength" && (
                              <p className="error">Only 6 digit number</p>
                            )}
                          </div>
                          <div
                            className="col-md-3"
                            style={{ marginRight: "10px" }}
                          >
                            <label className="form-label">Address 1</label>
                            <input
                              value={address1}
                              type="text"
                              className="form-control"
                              {...register("address1", {
                                onChange: (e) => setaddress1(e.target.value),
                              })}
                            />
                            {/* {errors?.address1?.type === "required" && (<p>This field is required</p>)} */}
                          </div>
                          <div
                            className="col-md-3"
                            style={{ marginRight: "10px" }}
                          >
                            <label className="form-label">Address 2</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("address2")}
                            />
                            {/* {errors?.address2?.type === "required" && (
                              <p className="error">This field is required</p>
                            )} */}
                          </div>
                          <div className="col-md-3">
                            <label className="form-label">Country</label>
                            <input
                              value={country}
                              type="text"
                              className="form-control"
                              {...register("country")}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="form-label">Contact Name</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("contactName")}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="form-label">Contact Email</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("contactEmail")}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="form-label">Contact Phone</label>
                      <input
                        type="number"
                        className="form-control"
                        {...register("contactPhone")}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Select Priority</label>
                      <Select
                        options={optionspriority}
                        name="Priority"
                        onChange={(event) => selectPriorityValue(event)}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Tags</label>
                      <Select
                        isMulti
                        options={options}
                        onChange={(e) => handlechangetag(e)}
                      />
                    </div>
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn CancelBtn me-3"
                        onClick={() => {
                          history.push("./Events");
                        }}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn submitBtn me-3">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Footer />
    </>
  );
}

export default AddEvents;
