import React, { useState } from "react";
import axios from "axios";
import "./categories.css";
import { useForm } from "react-hook-form";
import Input_Renderer from "../../components/input_renderer/Input_Renderer";

const Updatecategories = (props) => {
  const [category, setCategory] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onBlur" });

  function updateData(postdata) {
    let data = new FormData();

    data.append("categoryId", props.updatedData?._id);
    data.append(
      "category",
      category === " "
        ? props.updatedData?.postdata.category
        : postdata.category
    );

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/updateCategory`,
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        props.onEditDataFunction();
        reset();
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  return (
    <>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit Category Group
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => reset()}
            ></button>
          </div>

          <form onSubmit={handleSubmit(updateData)}>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 mb-3">
                  {/*<label className="form-label"> Category Group </label>*/}
                  <div className="position-relative">
                    {/*<input
                  // type="text"
                  // defaultValue={props.updatedData?.category}
                  // className="form-control"
                  // onChange={(e) => {
                  //   setCategory(e.target.value.replace(/[^A-Za-z ]/ig, ''))
                  // }}
  />*/}
                    <input
                      className="form-control"
                      defaultValue={props.updatedData?.category}
                      {...register("category", { required: true })}
                    />
                    {errors?.category?.type === "required" && (
                      <p>Field is required</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger CancelBtn"
                data-bs-dismiss="modal"
                onClick={() => reset()}
              >
                Cancel
              </button>
              <button
                type="submit"
                // onClick={updateData}
                className="btn submitBtn"
                data-bs-dismiss={
                  errors?.category?.type === "required" ? "" : "modal"
                }
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Updatecategories;
